












import { Vue, Component, Watch } from 'vue-property-decorator';
import TableTabMenu, { TableTabMenuItem } from '@/components/common/TableTabMenu.vue';
import { getCurrentMallNo } from '@/utils/mall';

const requireModules = require.context('./terms/', true, /\.vue$/);
const components = {};
requireModules.keys().map((fileName: string) => {
  const name = fileName.slice(fileName.lastIndexOf('/') + 1, fileName.length - 4);
  components[name] = () => import(`./terms/${fileName.slice(2)}`);
});

@Component({ components: { TableTabMenu, ...components } })
export default class Terms extends Vue {
  private menuList: TableTabMenuItem[] = [
    { name: 'AboutUs', title: this.$t('CONFIGURATION.TERMS.ABOUT_US') },
    { name: 'TermsOfService', title: this.$t('CONFIGURATION.TERMS.TERMS_OF_SERVICE') },
    { name: 'PrivacyPolicy', title: this.$t('CONFIGURATION.TERMS.PRIVACY_POLICY') },
    { name: 'PersonalInformationIframe', title: this.$t('CONFIGURATION.TERMS.PERSONAL_INFORMATION') },
    { name: 'Withdrawal', title: this.$t('CONFIGURATION.TERMS.WITHDRAWAL') },
  ];
  private focusTabName = 'AboutUs';

  private key = getCurrentMallNo(this);

  @Watch('$route')
  private onRouteChanged() {
    const key = getCurrentMallNo(this);
    if (this.key !== key) {
      this.key = key;
      this.focusTabName = 'AboutUs';
    }
  }
}
