









import { Vue, Component } from 'vue-property-decorator';
import { GetPartnersActivePartnerNo, PostPartnersRequest } from 'ncp-api-supporter';
import PartnerRegisterBasicInformation from '@/components/partner/register/PartnerRegisterBasicInformation.vue';
import PartnerRegisterDetailInformation from '@/components/partner/register/PartnerRegisterDetailInformation.vue';
import { Admin } from '@/types';
import { adminStore } from '@/components/board/Inquiries/SearchQuery';
import { openCreditChangePopup } from '@/helpers/popup';
@Component({
  components: {
    PartnerRegisterDetailInformation,
    PartnerRegisterBasicInformation,
  },
})
export default class PartnerRegisterCopyDetail extends Vue {
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;
  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  private partnerInfo = {
    // 참조 오류 때문에 참조값 초기 선언
    partner: {
      settlementManager: {},
      internationalCode: {},
      tradeBank: {},
      business: {},
      office: {},
      representative: {},
    },
    admin: {
      contactInfo: {},
    },
  } as GetPartnersActivePartnerNo;

  private partnerNo = '';
  private fetchPartnerInfo() {
    this.$api
      .getPartnersActivePartnerNo({
        pathParams: {
          partnerNo: `${this.$route.params.partnerNo}`,
        },
      })
      .then(({ data }) => {
        this.partnerInfo = data;
        this.partnerNo = `${data.partner.partnerNo}`;
      });
  }

  private registerPartner(request: PostPartnersRequest['data']) {
    const isRegistration = confirm(this.$t('PARTNER.REGISTER_CONFIRM') as string);

    if (!isRegistration) return;

    if (this.admin.plan === 'BASIC') {
      const result = confirm(this.$t('PARTNER.ALERT_BASIC_NOT_REGISTERED') as string);
      result && openCreditChangePopup(this.commerceAccount, this.admin.mallNos[0].toString());

      return;
    }

    this.$api
      .postPartnersPartnerNoCopy({
        pathParams: {
          partnerNo: this.partnerNo,
        },
        data: request,
      })
      .then(() => {
        alert('파트너 등록요청 완료');
        this.$router.push({ name: 'PartnerListPrev' });
      });
  }

  private created() {
    this.fetchPartnerInfo();
  }
}
