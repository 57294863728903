


































import { Vue, Component, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  GetSettlementsconfigurationsRequest,
} from 'ncp-api-supporter';
import { Nullable, PatchSettlementsConfigurationsRequest, SettlementsConfigurationsResponseV2 } from 'ncp-api-supporter/dist/types';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  SETTLEMENT_ORDER_STATUS_TYPE,
  SettlementOrderStatusType,
  settlementSettingRadioOption,
} from '@/const/contents/configuration/mallCreate';
import SettlementDay from '@/views/contents/configuration/basic/MallCreate/SettlementDay.vue';


@Component({
  components: { SettlementDay, SelectBox, RadioGroup, ToolTip },
})
export default class SettlementSetting extends Vue {
  @Prop({ required: true })
  private readonly isEditPage: boolean;

  @Prop({ required: true })
  private readonly mallNo: Nullable<string>;

  public get settlementConfigurationRequest(): PatchSettlementsConfigurationsRequest['data'] {
    return {
      settlementTargetStatus: this.settlementTargetStatus,
    };
  }

  private readonly radioOption = settlementSettingRadioOption;

  private settlementTargetStatus: SettlementOrderStatusType = SETTLEMENT_ORDER_STATUS_TYPE.BUY_CONFIRM;

  private async fetchSettlementsConfigurations() {
    const request: GetSettlementsconfigurationsRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };
    const { data } = await this.$api.getSettlementsConfigurationsV2(request);
    this.initializeSettlementConfiguration(data);
  }

  private initializeSettlementConfiguration({ settlementTargetStatus }: SettlementsConfigurationsResponseV2) {
    this.settlementTargetStatus = settlementTargetStatus;
  }

  private created() {
    this.fetchSettlementsConfigurations();
  }
}
