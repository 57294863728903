






import AdminIframe from '@/components/iframe/AdminIframe.vue';

export default {
  components: { AdminIframe },
  data: function() {
    return {
      remoteSrc: '',
    };
  },
  methods: {
    setRemoteSrc() {
      const query = this.$route.query;
      const queryObject = { ...query, serviceType: 'PRO' };
      const queryString = new URLSearchParams(queryObject).toString();

      this.remoteSrc =
        process.env.VUE_APP_ADMIN_REMOTE_URL + '/product/management/display-category?' + queryString;
    },
  },
  created: function() {
    this.setRemoteSrc();
  },
};
