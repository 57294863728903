










import { Vue, Component } from 'vue-property-decorator';
// components
import PartnerRegisterDetailInformation from '@/components/partner/register/PartnerRegisterDetailInformation.vue';
import { PostPartnersRequest } from 'ncp-api-supporter';
import { namespace } from 'vuex-class';
import { Admin } from '@/types';
import { openCreditChangePopup } from '@/helpers/popup';

const adminStore = namespace('admin');

@Component({
  components: {
    PartnerRegisterDetailInformation,
  },
})
export default class PartnerRegisterNew extends Vue {
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;
  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  private async registerPartner(request: PostPartnersRequest['data']) {
    if (this.admin.plan === 'BASIC') {
      const result = confirm(this.$t('PARTNER.ALERT_BASIC_NOT_REGISTERED') as string);
      result && openCreditChangePopup(this.commerceAccount, this.admin.mallNos[0].toString());

      return;
    }

    if (confirm(this.$t('PARTNER.REGISTER_CONFIRM') as string)) {
      await this.$api.postPartners({ data: request });
      this.$router.push({ name: 'PartnerListPrev' });
    }
  }
}
