export default Object.freeze({
  STATUS: '상태',
  STATUS_REGISTER: '등록대기',
  STATUS_NORMAL: '정상',
  STATUS_BLOCK_LOGIN: '로그인 잠김',
  STATUS_EXPIRATION: '기간만료',
  STATUS_LONG_NONE_LOGIN: '장기 미로그인 운영자',

  ADMIN_ID: '아이디',
  ADMIN_NO: '운영자번호',
  ADMIN_NAME: '운영자명',
  ADMIN_EMAIL: '이메일',
  ADMIN_MOBILE: '휴대폰번호',
  ADMIN_PHONE: '전화번호',
  DEPARTMENT: '소속부서',
  JOB_POSITION: '직급',
  JOB_DUTY: '직책',
  OTP_YN: 'OTP 등록 여부',
  ADMIN_NUMBER: '운영자번호',
  ADMIN_ROLE: '유형',
  AUTHORITY_GROUP: '권한그룹명',
  CREATED_DATE_TIME: '계정등록일시',
  PASSWORD_UPDATE_DATE_TIME: '비밀번호변경일시',
  LAST_LOGIN_DATE_TIME: '최종로그인일시',
  DUPLICATE_CHECK: '중복확인',
  PASSWORD: '비밀번호',

  OTP_USE_Y: '등록함',
  OTP_USE_N: '등록 안 함',

  PASSWORD_CONFIRM: '비밀번호 확인',
  PASSWORD_CONFIRM_PLACEHOLDER: '비밀번호 한 번 더 입력',
  PASSWORD_CONFIRM_INVALID_MESSAGE: '비밀번호와 비밀번호 확인 값이 일치하지 않습니다.',

  ADD_ADMIN: '운영자 등록',
  DELETE_ADMIN: '운영자 삭제',
  KEYWORD_PLACEHOLDER: '검색어 입력',
  STATUS_TOOLTIP:
    '운영자 등록 시 계정 등록일 기준으로 7일 이후에는 등록이 불가합니다.\n7일 이후 등록을 원하시면 [운영자 등록] 페이지에서 신규로 등록해주세요.',
  STATUS_LONG_NONE_LOGIN_TOOLTIP:
    '* 최종로그인일로 부터 3개월(90일) 이상 접속하지 않은 운영자를 검색 합니다.\n\n' +
    '<strong>[개인정보의 안전성 확보에 필요한 조치에 관한 사항]</strong>\n' +
    '개인정보처리자의 변경이 있는 경우 지체 없이 개인정보시스템의 접근 권한을 변경,말소 해야 하므로\n' +
    '장기 미로그인 운영자 및 인사이동, 퇴사 운영자는 계정 삭제 처리 등 즉각 관리하시는 것을 권고 드립니다.',

  ADD_ADMIN_FORM_TITLE: '운영자 정보 입력',
  ADD_ADMIN_PRIVACY_TERMS_TITLE: '개인정보 수집 및 이용 동의',

  ADD_ADMIN_PRIVACY_TERMS_1: '1. 수집 항목 :',
  ADD_ADMIN_PRIVACY_TERMS_2:
    '1) 계정 생성을 요청한 이용자의 개인정보를 수집합니다.\n' +
    '- (필수) ID, 비밀번호, 성명, 이메일, 휴대폰번호\n' +
    '- (선택) 전화번호, 소속부서, 직급, 직책\n' +
    '2) 서비스 이용 과정에서 다음과 같은 정보가 생성되어 수집될 수 있습니다.\n' +
    '- 접속 IP주소, 접속 일시, 서비스 이용내역\n\n',
  ADD_ADMIN_PRIVACY_TERMS_3: '2. 수집/이용 목적 :',
  ADD_ADMIN_PRIVACY_TERMS_4: '운영 업무 처리 권한 부여\n\n',
  ADD_ADMIN_PRIVACY_TERMS_5: '3. 개인정보 보유 및 이용기간 :',
  ADD_ADMIN_PRIVACY_TERMS_6:
    '이용자의 개인정보는 원칙적으로 운영자 삭제 시까지 보존하며 운영자 삭제 시 지체 없이 파기합니다. 단, ' +
    '관계법령의 규정에 의하여 보전할 필요가 있는 경우 명시한 기간 동안 보존하며, 그 외 다른 목적으로는 절대 ' +
    '사용하지 않습니다.\n운영자 삭제 시 해당 운영자의 개인정보를 지체 없이 파기하며, 복구 및 재생되지 않도록 ' +
    '기술적 방법을 이용하여 완전하게 삭제합니다.',
  ADD_ADMIN_PRIVACY_TERMS_7: '4. 개인정보 수집 및 이용 동의를 거부할 권리',
  ADD_ADMIN_PRIVACY_TERMS_8:
    '이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. \n단, 개인정보 수집 및 이용 동의를 거부하실 경우 계정 생성/이용 제한됩니다. (제한 서비스: 계정 생성 및 시스템 이용)',

  ADD_ADMIN_PRIVACY_TERMS_AGREE: '[필수] 개인정보 수집 및 이용 동의',

  GO_AUTHORITY_GROUP: '권한그룹 바로가기',

  DEPARTMENT_PLACEHOLDER: '소속부서 입력',
  JOB_DUTY_PLACEHOLDER: '직책 입력',

  ADD_POPUP_TITLE: '운영자 등록',
  ADD_POPUP_NAME: '운영자명',
  ADD_POPUP_EMAIL: '이메일',
  ADD_POPUP_AUTHORITY_GROUP: '권한그룹',
  ADD_POPUP_NAME_PLACEHOLDER: '이름 입력',
  ADD_POPUP_SELECT_AUTHORITY_GROUP_MESSAGE: '권한그룹을 선택하세요.',
  ADD_POPUP_PHONE_NO: '전화번호 입력',
  ADD_POPUP_MOBILE_NO: '휴대폰번호 입력',
  JOB_POSITION_PLACEHOLDER: '직급 입력',

  CONFIRM_DELETE: '선택하신 부운영자를 삭제 하시겠습니까?\n본인의 계정인 경우 삭제 후 즉시 로그아웃 됩니다.',
  CONFIRM_CANCEL_EDIT: '수정을 취소하시겠습니까?\n입력하신 내용은 저장되지 않습니다.',

  ALERT_DELETE_SUCCESS: '해당 운영자가 삭제되었습니다.',
  ALERT_DELETE_FAIL_BY_MD: '선택하신 부운영자는 상품 담당자로 지정되어 있어 삭제가 불가합니다.',
  ALERT_INSERT_MOBILE: '휴대폰번호를 입력해주세요.',
  ALERT_MIN_LENGTH_OF_MOBILE: '정확한 휴대폰번호를 입력해주세요.',
  ALERT_INSERT_NAME: '운영자명을 입력해주세요.',
  ALERT_INSERT_EMAIL: '이메일을 입력해주세요.',
  ALERT_SELECT_AUTHORITY_GROUP: '권한그룹을 선택해주세요.',
  ALERT_NOT_SELECT_AUTHORITY_GROUP_BY_MD:
    '선택하신 부운영자는 상품 별도 담당자로 지정되어 있어 해당 메뉴의 권한 해제가 불가합니다.',
  ALERT_USE_EMAIL: `메일 관리 사용여부가 ‘사용 안 함‘ 상태입니다.\n메일 관리 > 사용여부를 ‘사용함‘ 으로 변경 후 계정등록 메일 발송이 가능합니다.`,

  ALERT_INSERT_ID: '아이디를 입력해주세요.',
  ALERT_INVALID_TEXT: '입력불가한 문자가 포함되어 있습니다.',
  ALERT_ID_EXIST: '이미 사용중인 아이디입니다.\n다른 아이디를 입력하여 주세요.',
  ALERT_ID_NOT_EXIST: '사용 가능한 아이디입니다.',
  ALERT_CHECK_ID_EXIST: '아이디 중복확인이 안되었습니다.',
  ALERT_INSERT_PASSWORD: '비밀번호를 입력해주세요.',
  ALERT_INSERT_PASSWORD_CONFIRM: '비밀번호 확인을 입력해주세요.',
  ALERT_CHECK_PRIVACY_TERMS_AGREE: '개인정보 수집 및 이용동의에 체크해주세요.',
  ALERT_DELETED_GROUP: '선택한 권한 그룹이 삭제되었습니다.\n권한 그룹을 다시 선택해주세요.',

  EDIT_ME_POPUP_TITLE: '내 계정정보',

  ADMIN_ROLE_MASTER: '대표 운영자',
  ADMIN_ROLE_NORMAL: '부운영자',

  ADMIN_STATUS_ACTIVE: '정상',
  ADMIN_STATUS_WAITING: '등록대기',
  ADMIN_STATUS_WAITING_EXPIRATION: '기간만료',
  ADMIN_STATUS_LOCKED: '로그인 잠금',
  ADMIN_STATUS_ACTIVE_LONG_NONE_LOGIN: '장기 미로그인',

  SMS_AUTH_REQUEST: '인증번호 발송',
  SMS_AUTH_RE: '재인증',
  ALERT_SMS_AUTH_REQUEST_SUCCESS:
    '인증번호가 발송되었습니다.\n휴대폰번호로 발송된 인증번호를 입력하여 인증을 완료해 주세요.',
  INSERT_SMS_AUTH_NUMBER_PLACEHOLDER: '인증번호를 입력해주세요.',
  MAXIMUM_AUTHENTICATION_ATTEMPTS_EXCEEDED_10:
    '인증을 10회 이상 실패하였습니다. \n자동등록방지 문자 입력 후 다시 시도해주세요.',

  SMS_AUTH_OVER_TIME_MESSAGE: '유효시간 경과되었습니다. 다시 [인증번호 발송] 클릭하여 발급된 인증번호를 입력해 주세요.',

  ALERT_WRONG_MOBILE_NO: '휴대폰번호 입력내용이 올바르지 않습니다. 휴대폰번호 형식을 확인해주세요.',
  ALERT_SMS_AUTH_COMPLETE: '휴대폰 번호인증을 진행해주세요.',

  ALERT_MAX_ADMIN_LENGTH: '부운영자는 최대 100명까지 등록 가능합니다.',
  ALERT_STATUS_NONE: '조회하려는 상태를 체크해주세요.',

  ALERT_ALEADY_ADMIN: '이미 가입 완료 된 운영자 계정입니다.',

  REGISTER_SUCCESS: '운영자 등록이 완료되었습니다.',

  REGISTER_DELETE: '운영자 계정등록 삭제',
  REGISTER_DELETE_SUB: '관리자에 의해 계정등록이 삭제되었습니다.',

  REGISTER_RETRY_MESSAGE: '확인 후 재 신청 진행해 주시기 바랍니다.',

  REGISTER_PERIOD_EXPIRED: '신청 이용 기간 만료',
  REGISTER_PERIOD_EXPIRED_SUB: '이용 가능한 기간이 만료되었습니다.',

  OTP_SETTING: 'OTP 설정',
  OTP_RE_SETTING_BUTTON: '재설정하기',
  OTP_SETTING_BUTTON: '설정하기',
  OTP_SETTING_DESC: '관리자 보안인증 수단으로 OTP를 사용하는 경우 설정하여 주시기 바랍니다.',

  EXTERNAL_CONNECTION: '외부접속',

  NOTICE_TO_EDIT_CONSOLIDATION_ADMIN_INFO1: '* 대표 운영자 연락처 정보는 ',
  NOTICE_TO_EDIT_CONSOLIDATION_ADMIN_INFO2: '에서 관리할 수 있습니다.',
  CONSOLIDATION_MY_PAGE: '통합 마이페이지',

  ALERT_IP_ADDRESS_GUIDE:
    '해당 운영자가 로그인 시 등록된 IP로만 로그인 가능하도록 설정 할 수 있습니다. (현재 접속 IP : {ip})',
  IP_ACCESS_CONTROL: '운영자 IP 접속제한 사용여부',
  REGISTRATION_ACCESSIBLE_IP: '운영자 접속가능 IP 등록',
  ALERT_BASIC_NOT_REGISTERED:
    'basic의 경우 최대 3명까지 운영자 등록이 가능합니다. pro 요금제 이용 시, 추가 서비스 이용이 가능합니다. 요금제를 변경 하시겠습니까?',
});
