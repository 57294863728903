













































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getAdminGridOption } from '@/const/contents/configuration/admin';
import { GridEventProps } from '@/types';
import Grid from '@/components/common/grid/Main.vue';
import { throwPopup } from '@/helpers/popup';
import { Getter } from 'vuex-class';
import { Admin as AdminType } from '@/types/admin';
import { logout } from '@/utils/token';
import { GetAuthorityGroupsByGroupNoResponse } from 'ncp-api-supporter';
import { setNoDataMessage } from '@/helpers/grid';
import ReasonDetailLayerPopup from '@/components/ReasonDetailLayerPopup.vue';

@Component({ components: { Grid, ReasonDetailLayerPopup } })
export default class Admin extends Vue {
  @Getter('admin/getAdmin') private me!: AdminType;

  private gridProps = getAdminGridOption();

  private contents = [];
  private totalCount = 0;

  private reasonDetailModal = { isOpen: false, reasonDetail: '' };
  private adminNo = 0;

  @Watch('$route')
  private onQueryStringChanged() {
    this.getAdminsService();
  }

  created() {
    setNoDataMessage(this.$t('NO_RESULT').toString());
    this.getAdminsService();
  }

  private getAdminsService(): void {
    const query = { page: 1, pageSize: 30 };
    Object.assign(query, this.$route.query);

    this.$api
      .getAdminsService({
        params: query,
      })
      .then(response => {
        if (response && response.status === 200) {
          this.contents = response.data.contents;
          this.totalCount = response.data.totalCount;
        }
      });
  }

  // get authority group info by groupNo
  private getAuthorityGroupsByGroupNo(groupNo: number): Promise<GetAuthorityGroupsByGroupNoResponse | void> {
    return this.$api
      .getAuthorityGroupsByGroupNo({
        pathParams: {
          authorityGroupNo: groupNo + '',
        },
      })
      .then(response => {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch();
  }

  private deleteAdminsServiceAdminNo(adminNo: number): Promise<boolean> {
    return this.$api
      .deleteAdminsServiceByAdminNo({       
        params: {
          adminNo: adminNo.toString(),
          reasonDetail: this.reasonDetailModal.reasonDetail,
        }})
      .then(response => {
        if (response && response.status === 204) {
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  private addAdmin(): void {
    if (this.totalCount > 100) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_MAX_ADMIN_LENGTH'));
      return;
    }

    throwPopup({
      name: 'AddAdmin',
      data: { adminCount: this.totalCount },
    }).then(response => {
      if (response && response.state === 'confirm') {
        this.getAdminsService();
      }
    });
  }

  private deleteAdmin(adminNo: number): void {
    if (!confirm(this.$t('CONFIGURATION.ADMIN.CONFIRM_DELETE') as string)) {
      return;
    }

    this.deleteAdminsServiceAdminNo(adminNo).then(success => {
      if (success) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_DELETE_SUCCESS'));

        if (this.me.no === adminNo) {
          logout();
        } else {
          this.getAdminsService();
        }
      }
    });
  }

  private onItemClicked(gridProps: GridEventProps): void {
    switch (gridProps.columnName) {
      case 'adminId': {
        const event = (gridProps as any).nativeEvent;
        const target = event.target as HTMLElement;
        if (target.tagName.toUpperCase() !== 'A') {
          return;
        }

        const targetAdmin = this.contents.filter(c => c.adminNo === gridProps.rowKey)[0];

        if (!targetAdmin) {
          event.stopPropagation();
          event.preventDefault();
          return;
        }

        if (targetAdmin.adminRole === 'MASTER' && targetAdmin.adminNo !== this.me.no) {
          event.stopPropagation();
          event.preventDefault();
          return;
        }

        break;
      }
      case 'delete': {
        const target = (gridProps as any).nativeEvent.target as HTMLElement;
        if (target.tagName.toUpperCase() !== 'BUTTON') {
          return;
        }

        if (gridProps.rowKey) {
          const adminNo = Number(gridProps.rowKey);
          const targetAdmin = this.contents.find(c => c.adminNo === adminNo);

          if (!adminNo || !targetAdmin) {
            return;
          }

          this.adminNo = adminNo;
          this.openReasonDetailModal();
        }

        break;
      }
    }
  }

  private openReasonDetailModal(): void {
    this.reasonDetailModal.isOpen = true;
  }

  private closeReasonDetailModal() {
    this.reasonDetailModal.isOpen = false;
  }

  private confirmReasonDetailModal(value: string) {
    this.reasonDetailModal.reasonDetail = value;

    if (!this.reasonDetailModal.reasonDetail) {
      alert('사유를 입력해주세요');
      
      return;
    }

    this.closeReasonDetailModal();
    this.deleteAdmin(this.adminNo);
  }
}
