











































import { Vue, Component, Watch } from 'vue-property-decorator';
import { GetAuthorityGroupsRequest } from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import { getAuthorityGroupGridOption } from '@/const/contents/configuration/authorityGroup';
import { GridEventProps } from '@/types';
import { throwWindowPopup } from '@/helpers/popup';
import { setNoDataMessage } from '@/helpers/grid';
import ReasonDetailLayerPopup from '@/components/ReasonDetailLayerPopup.vue';

@Component({ components: { Grid, ReasonDetailLayerPopup } })
export default class AuthorityGroup extends Vue {
  private gridProps = getAuthorityGroupGridOption();
  private gridDisplayOptions = { hasExcelDownloadButton: false, hasSettingButton: false };
  private reasonDetailModal = { isOpen: false, reasonDetail: '' };
  private contents = [];
  private totalCount = 0;
  private authorityGroupNo = 0;

  @Watch('$route')
  private onQueryStringChanged() {
    this.getAuthorityGroups();
  }

  created() {
    setNoDataMessage(this.$t('NO_RESULT').toString());
    this.getAuthorityGroups();
  }

  private getAuthorityGroups(): void {
    const query = { page: 1, pageSize: 30 };
    Object.assign(query, this.$route.query);

    const request: GetAuthorityGroupsRequest = {
      params: query,
    };

    this.$api.getAuthorityGroups(request).then(response => {
      if (response && response.status === 200) {
        this.contents = response.data.contents;
        this.totalCount = response.data.totalCount;
      }
    });
  }

  // delete authority group
  private deleteAuthorityGroupsByGroupNo(authorityGroupNo): Promise<boolean> {
    return this.$api
      .deleteAuthorityGroupsByGroupNo({
        pathParams: {
          authorityGroupNo,
        },
        params: {
          reasonDetail: this.reasonDetailModal.reasonDetail
        }
      })
      .then(response => response && response.status === 204)
      .catch(() => false);
  }

  // delete authority group
  private deleteAuthorityGroup(authorityGroupNo: number): void {
    if (!confirm(this.$t('CONFIGURATION.AUTHORITY_GROUP.CONFIRM_DELETE') as string)) {
      return;
    }

    this.deleteAuthorityGroupsByGroupNo(authorityGroupNo + '').then(success => {
      if (success) {
        alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_SUCCESS_DELETE'));
        this.$router.go(0);
      }
    });
  }

  private onItemClicked(gridProps: GridEventProps): void {
    const target = (gridProps as any).nativeEvent.target as HTMLElement;

    switch (gridProps.columnName) {
      case 'name':
        if (target.tagName.toUpperCase() !== 'A') {
          return;
        }

        this.$router.push(`/configuration/management/authority-group/edit?groupNo=${gridProps.rowKey}`);
        break;
      case 'adminCount': {
        if (target.tagName.toUpperCase() !== 'A') {
          return;
        }

        throwWindowPopup(
          'AuthorityGroupMemberListPopup',
          {
            authorityGroupNo: gridProps.rowKey,
          },
          'md',
          null,
          '',
          { authorityGroupNo: gridProps.rowKey + '' },
        );

        break;
      }
      case 'copy':
        if (target.tagName.toUpperCase() !== 'A') {
          return;
        }

        if (this.totalCount >= 100) {
          alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_MAX_100'));
          return;
        }

        this.$router.push(`/configuration/management/authority-group/register?groupNo=${gridProps.rowKey}`);
        break;
      case 'delete': {
        if (target.tagName.toUpperCase() !== 'BUTTON' || !gridProps.rowKey) {
          return;
        }

        const authorityGroupNo = Number(gridProps.rowKey);
        const authorityGroup = this.contents.filter(c => c.no === authorityGroupNo)[0];

        if (!authorityGroup) {
          return;
        }

        if (authorityGroup.adminCount > 0) {
          alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_CAN_NOT_DELETE'));

          return;
        }

        this.authorityGroupNo = authorityGroupNo;
        this.openReasonDetailModal();

        break;
      }
    }
  }

  private addAuthorityGroup(): void {
    if (this.totalCount >= 100) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_MAX_100'));
      return;
    }

    this.$router.push('/configuration/management/authority-group/register');
  }

  private openReasonDetailModal(): void {
    this.reasonDetailModal.isOpen = true;
  }

  private closeReasonDetailModal() {
    this.reasonDetailModal.isOpen = false;
  }

  private confirmReasonDetailModal(value: string) {
    this.reasonDetailModal.reasonDetail = value;

    if (!this.reasonDetailModal.reasonDetail) {
      alert('사유를 입력해주세요');
      
      return;
    }

    this.closeReasonDetailModal();
    this.deleteAuthorityGroup(this.authorityGroupNo);
  }
}
