






































































































































































































































































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  getUsesOrNot,
  i18nMallCreate,
  storageMaxQuantity,
  storagePeriod,
  UsedPhoneAuthenticationType,
} from '@/const/contents/configuration/mallCreate';
import { GetOrderConfigsOrder, OrderconfigsCartResponse } from 'ncp-api-supporter';
import { getSelectMonths } from '@/utils/dateFormat';

@Component({
  components: { TextInput, ToolTip, RadioGroup, SelectBox },
})
export default class OrderSetting extends Vue {
  @PropSync('orderConfig') private orderConfigSync!: GetOrderConfigsOrder['value']; //일단 필요없음
  @PropSync('cartConfig') private cartConfigSync!: OrderconfigsCartResponse['value'];
  @PropSync('claimsConfig') private claimsConfigSync!: boolean;
  @Prop({ required: true }) private readonly usedPhoneAuthentication!: UsedPhoneAuthenticationType;
  @Prop({ required: true }) private readonly isEditPage!: boolean;

  private getUsesOrNot = getUsesOrNot;
  private storageMaxQuantity = storageMaxQuantity;
  private storagePeriod = storagePeriod;
  private getSelectMonths = getSelectMonths;

  //TOdo api 수정 후 변경
  private authForChild = false;

  private onChangeAuthForChild(value) {
    if (value === true && this.usedPhoneAuthentication === 'KCP') {
      alert(i18nMallCreate('AUTH_FOR_CHILD_ALERT'));
      this.$nextTick(() => (this.authForChild = false));
    }
  }

  private handleExcludesAutoBuy() {
    const checkbox = this.$refs.excludesAutoBuy as HTMLInputElement;

    if (!this.orderConfigSync.excludesAutoBuyConfirmOfServiceProductGroup) {
      this.orderConfigSync.excludesAutoBuyConfirmOfServiceProductGroup = true;
      checkbox.checked = true;

      return;
    }

    const result = confirm(this.$t('MALL.FORM.EXCLUDE_AUTO_BUY_CONFIRM').toString());

    this.orderConfigSync.excludesAutoBuyConfirmOfServiceProductGroup = !result;
    checkbox.checked = !result;
  }

  private changeDeleteExpiredOrder(e: Event) {
    const target = e.target as HTMLInputElement;

    if (!this.orderConfigSync.deleteExpiredOrder && target.value === 'true' && !confirm('5년 경과한 주문 데이터를 매일 자동으로 삭제 처리하시겠습니까? 삭제된 데이터는 복원 및 복구가 불가합니다.')) {
      e.preventDefault();
    }

    if (!!this.orderConfigSync.deleteExpiredOrder && target.value === 'false' && !confirm('개인정보보호법에 따라 보유기간 경과 및 처리 목적이 달성된 개인정보는 지체없이 파기해야 합니다. 설정을 변경하는 경우 5년 경과 주문 자동 삭제 처리되지 않습니다. 설정을 변경하시겠습니까?')) {
      e.preventDefault();
    }
  }
}
