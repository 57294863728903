









































































































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  getAuthorityGroupCheckBoxOption,
  getAuthorityGroupRadioBoxOption,
  getAuthorityGroupToolTipOption,
} from '@/const/contents/configuration/authorityGroup';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import { Getter } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';
import { CheckBoxGroupOption } from '@/helpers/type';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { throwWindowPopup } from '@/helpers/popup';
import { AuthorityGroupsCustomType } from '../AuthorityGroupModify.vue';

@Component({ components: { ToolTip, CheckboxGroup, TextInput, RadioGroup } })
export default class AuthorityGroupModifyBasic extends Vue {
  @Prop({ required: true }) private readonly isEditMode!: boolean;
  @Prop({ required: true }) private readonly originData: AuthorityGroupsCustomType;
  @PropSync('basicData', { required: true }) private basicDataSync!: AuthorityGroupsCustomType;

  @Getter('mall/getMalls') private malls!: Mall[];

  private toolTipOption = getAuthorityGroupToolTipOption();
  private checkBoxOption = getAuthorityGroupCheckBoxOption();
  private radioBoxOption = getAuthorityGroupRadioBoxOption();

  private mallSelectData = [];
  private mallUnselectData = [];

  private selectedMall = '';
  private allSelectedMall = '';

  private getMallCheckBoxOption(bSelected = false): CheckBoxGroupOption<number> {
    const data = bSelected ? this.mallSelectData : this.mallUnselectData;
    return this.checkBoxOption.getMallCheckBoxOption(data);
  }

  private changeSelectMall(value: string) {
    if (!this.isEditMode) {
      this.mallUnselectData = this.mallUnselectData.map(m => ({ ...m, selected: value.includes(m.value) }));
      this.changeMall();
      return;
    }
    const unselectedIndex = this.mallUnselectData.findIndex(data => value === data.value.toString());
    if (unselectedIndex !== -1) {
      const selected = this.mallUnselectData.splice(unselectedIndex, 1);
      this.mallSelectData = this.mallSelectData
        .concat(selected)
        .map(data => ({ ...data, selected: true }))
        .sort((a, b) => (a.value > b.value ? 1 : -1));
    }
    this.changeMall();
  }

  private changeUnselectedMall(value: string) {
    const index = this.mallSelectData.findIndex(data => !value.includes(data.value.toString()));
    if (index !== -1) {
      const unselected = this.mallSelectData.splice(index, 1);
      this.mallUnselectData = this.mallUnselectData
        .concat(unselected)
        .map(data => ({ ...data, selected: false }))
        .sort((a, b) => (a.value > b.value ? 1 : -1));
    }
    this.changeMall();
  }

  private changeMall() {
    if (!this.isEditMode) {
      this.basicDataSync.mallNos = this.mallUnselectData.filter(({ selected }) => selected).map(({ value }) => value);
      this.selectedMall = this.basicDataSync.mallNos.join(',');

      const selectedLength = this.mallUnselectData.filter(({ selected }) => selected).length;
      if (selectedLength === this.malls.length) {
        this.allSelectedMall = 'true';
      } else {
        this.allSelectedMall = '';
      }
      this.changeData();
      return;
    }

    this.basicDataSync.mallNos = this.mallSelectData.map(({ value }) => value);
    this.selectedMall = this.basicDataSync.mallNos.join(',');

    if (this.mallUnselectData.length === this.malls.length) {
      this.allSelectedMall = '';
    } else if (this.mallUnselectData.length === 0) {
      this.allSelectedMall = 'true';
    }
    this.changeData();
  }

  private toggleAllSelectMall(value: string) {
    if (!this.isEditMode) {
      this.mallUnselectData = this.mallUnselectData.map(data => ({ ...data, selected: value === 'true' }));
      this.selectedMall = value === 'true' ? this.mallUnselectData.map(({ value }) => value).join(',') : '';
      return;
    }

    if (value === 'true') {
      this.mallSelectData = this.mallSelectData.concat(this.mallUnselectData);
      this.mallUnselectData = [];
      this.selectedMall = this.mallSelectData.map(({ value }) => value).join(',');
    } else {
      const bAllSelect = this.mallSelectData.length === this.malls.length;

      if (bAllSelect) {
        this.mallUnselectData = this.mallUnselectData.concat(this.mallSelectData.filter(({ disabled }) => !disabled));
        this.mallSelectData = this.mallSelectData.filter(({ disabled }) => disabled);
        this.selectedMall = this.mallSelectData.map(({ value }) => value).join(',');
      }
    }
  }

  @Watch('basicData')
  private updateBasicDataSync() {
    const mallNos = [];
    const data = [];
    this.malls.map(m => {
      data.push({
        id: m.mallNo,
        value: m.mallNo,
        display: m.mallName,
        disabled: false,
        selected: false,
      });
    });

    if (this.isEditMode) {
      data.map(m => {
        if (this.basicDataSync.mallNos.filter(n => n === m.value).length > 0) {
          m.selected = true;
          m.disabled = this.basicDataSync.adminCount > 0;

          mallNos.push(m.value);
        }
      });
    }

    this.mallSelectData = data.filter(({ selected }) => selected);
    this.mallUnselectData = data.filter(({ selected }) => !selected);
    this.selectedMall = mallNos.toString();
  }

  private isUpdatedInitialData(): boolean {
    return !this.basicDataSync.name || !this.selectedMall;
  }
  private isNotChanged(): boolean {
    const { name, description, permitsPrivateInformation, smsReceived } = this.originData;
    const sortMalls = mallStrNos =>
      mallStrNos
        .split(',')
        .map(n => Number(n))
        .sort((a, b) => a - b)
        .join('');
    return (
      name === this.basicDataSync.name &&
      description === this.basicDataSync.description &&
      permitsPrivateInformation === this.basicDataSync.permitsPrivateInformation &&
      smsReceived === this.basicDataSync.smsReceived &&
      sortMalls(this.originData.mallNos?.join(',') || '') === sortMalls(this.selectedMall)
    );
  }
  @Watch('basicDataSync.name')
  @Watch('basicDataSync.description')
  @Watch('basicDataSync.permitsPrivateInformation')
  @Watch('basicDataSync.smsReceived')
  private changeData() {
    if (this.isUpdatedInitialData()) return;
    this.$emit('changeData', this.isNotChanged());
  }

  created() {
    this.updateBasicDataSync();
  }

  public validate(): boolean {
    if (!this.selectedMall) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_CHECK_MALL'));
      return false;
    }

    if (!this.basicDataSync.name) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_INSERT_NAME'));
      (this.$refs.name as HTMLElement).focus();
      return false;
    }

    if (!this.isEditMode && !this.basicDataSync.reasonDetail) {
      alert('사유를 입력해주세요.');
      (this.$refs.reasonDetail as HTMLElement).focus();
      return false;
    }

    return true;
  }

  private openAuthorityGroupMemberListPopup(): void {
    throwWindowPopup(
      'AuthorityGroupMemberListPopup',
      {
        authorityGroupNo: Number(this.$route.query.groupNo),
      },
      'md',
      result => {
        if (result && result.data && result.data.adminId) {
          this.$router.push(`/configuration/management/admin/edit?adminNo=${result.data.adminId}`);
        }
      },
    );
  }
}
