















































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import AddressInput from '@/components/common/input/AddressInput.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import EmailInput from '@/components/common/input/EmailInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import Grid from '@/components/common/grid/Main.vue';
// const
import countries from '@/const/words/country';
import tradeBanks from '@/const/words/tradeBank';
import sellerTaxationTypes from '@/const/words/sellerTaxationTypes';
import { dependencyOptions } from '@/const/contents/partner';
// types
import { Contracts, PartnersInfoByNo, PutContracts, MasterAdminInfo } from 'ncp-api-supporter';
// init
import {
  partnerModificationInitialState,
  getExternalAccessOptions,
  getIpToolTipInformation,
  ipAccessControlRadioOption,
} from '@/views/contents/partner/modification/PartnerModificationInit';
// nav
import { throwBottomNavigation } from '@/helpers/bottomNav';
// requests
import {
  getPartnerInfoRequest,
  getContractsRequest,
  putPartnersByPartnerNoRequest,
  putContractsRequest,
  deletePartnerRequest,
} from '@/views/contents/partner/modification/apis/apiRequests';
// api methods
import { resendEmail } from '@/views/contents/partner/modification/apis/apiMethods';
import CsAdminInformation from '@/components/partner/modification/CsAdminInformation.vue';
import { NavButton } from '@/types/bottomNav';
import { i18n } from '@/main';
import { emptyValueFormatter } from '@/utils/grid/formatterUtils';
import entryContractRendererByContractStatus from './grid/renderers/entryContractRendererByContractStatus';
import contractFilesUpdateHistoryRenderer from './grid/renderers/contractFilesUpdateHistoryRenderer';
import contractFilesRenderer from './grid/renderers/contractFilesRenderer';
import contractStatusRendererByContractStatus from './grid/renderers/contractStatusRendererByContractStatus';
import promotionAgreedRendererByContractStatus from './grid/renderers/promotionAgreedRendererByContractStatus';
import commissionRateRenderer from './grid/renderers/commissionRateRenderer';
import useDefaultCommissionRateRendererByContractStatus from './grid/renderers/useDefaultCommissionRateRendererByContractStatus';

@Component({
  components: {
    SelectBox,
    AddressInput,
    TextInput,
    EmailInput,
    RadioGroup,
    ToolTip,
    Grid,
    CsAdminInformation,
  },
})
export default class PartnerModification extends Vue {
  // 사업자기본정보
  @Ref()
  private readonly partnerNameRef!: TextInput;
  @Ref()
  private readonly companyNameRef!: TextInput;
  @Ref()
  private readonly businessRegistrationNoRef!: TextInput;
  @Ref()
  private readonly representativeNameRef!: TextInput;
  @Ref()
  private readonly businessTypeRef!: TextInput;
  @Ref()
  private readonly businessConditionRef!: TextInput;
  @Ref()
  private readonly representativePhoneNoRef!: TextInput;
  @Ref()
  private readonly representativeEmailRef!: EmailInput;

  // 정산관련정보
  @Ref()
  private readonly settlementManageNameRef!: TextInput;
  @Ref()
  private readonly settlementManagePhoneNoRef!: TextInput;
  @Ref()
  private readonly settlementManagerEmailRef!: EmailInput;
  @Ref()
  private readonly tradeBankNameRef!: TextInput;
  @Ref()
  private readonly tradeBankAccountRef!: TextInput;
  @Ref()
  private readonly tradeBankDepositorNameRef!: TextInput;

  // 대표운영자
  @Ref()
  private readonly masterAdminPermittedIpAddressRef!: TextInput;

  @Ref()
  private readonly tradeMallGridRef!: Grid;
  private isDependencyYn = false;

  private readonly countries = countries;
  private readonly sellerTaxationTypes = sellerTaxationTypes;
  private readonly dependencyYnOptions = dependencyOptions;
  private readonly tradeBanks = tradeBanks;
  private readonly externalAccessOptions = getExternalAccessOptions();
  private readonly ipToolTipInformation = getIpToolTipInformation();
  private readonly gridOption = {
    header: {
      columns: [],
    },
    columns: [
      {
        header: 'PARTNER.EDIT.MALL_NAME',
        name: 'mallName',
        align: 'center',
        minWidth: 100,
      },
      {
        header: 'PARTNER.EDIT.CHARGE_MD_NAME',
        name: 'merchandiserName',
        align: 'center',
      },
      {
        header: 'PARTNER.EDIT.USE_DEFAULT_COMMISSION_RATE',
        name: 'usesDefaultCommissionRate',
        align: 'center',
        minWidth: 160,
        renderer: useDefaultCommissionRateRendererByContractStatus,
      },
      {
        header: 'PARTNER.EDIT.COMMISSION_RATE',
        name: 'pureCommissionRate',
        align: 'center',
        renderer: commissionRateRenderer,
      },
      {
        header: 'PARTNER.EDIT.AGREE_PROMOTION',
        name: 'promotionAgreed',
        align: 'center',
        minWidth: 160,
        renderer: promotionAgreedRendererByContractStatus,
      },
      {
        header: 'PARTNER.EDIT.CONTRACT_STATUS',
        name: 'contractStatus',
        align: 'center',
        minWidth: 110,
        renderer: contractStatusRendererByContractStatus,
      },
      {
        header: 'PARTNER.EDIT.CONTRACT_FILE',
        name: 'contractFiles',
        align: 'center',
        minWidth: 110,

        renderer: contractFilesRenderer,
      },
      {
        header: 'PARTNER.EDIT.CONTRACT_FILE_UPDATE_HISTORY',
        name: 'contractFilesUpdateHistory',
        align: 'center',
        minWidth: 140,
        renderer: contractFilesUpdateHistoryRenderer,
      },
      {
        header: 'PARTNER.EDIT.STANDING_POINT_CONTRACT',
        name: 'latestEntryContractAgreed',
        align: 'center',
        minWidth: 180,
        renderer: entryContractRendererByContractStatus,
      },
      {
        header: 'PARTNER.EDIT.START_YMDT',
        name: 'startedDateTime',
        align: 'center',
        minWidth: 140,
        formatter: cellData => emptyValueFormatter(cellData.value),
      },
      {
        header: 'PARTNER.EDIT.EXTERNAL_API_KEY',
        name: 'externalApiKey',
        align: 'center',
        minWidth: 120,
      },
      {
        header: 'DELETE',
        name: 'delete',
        align: 'center',
        minWidth: 60,
				renderer: ({grid, rowKey}) => {
          const { partnerStatus } = this.$route.query;

          const { contractNo, contractStatus } = grid.getRow(rowKey) as Contracts;

          const cellEl = document.createElement('div');
          cellEl.className = 'tui-grid-cell-content';

          if (partnerStatus === 'ACTIVE' && ['WAITING', 'INVESTIGATION'].includes(contractStatus)) {
            const deleteBtn = document.createElement('a');
            deleteBtn.href = 'javascript:;';
            deleteBtn.className = 'underline';
            deleteBtn.textContent = i18n.t('DELETE') as string;

            deleteBtn.addEventListener('click', () => {
              if (!confirm(i18n.t('PARTNER.EDIT.CONFIRM_DELETE_MALL') as string)) {
                return;
              }

              this.$api.deleteContract({
                contractNo,
              })
                .then(() => {
                  this.reloadContracts();
                });
            });

            cellEl.append(deleteBtn);
          } else {
            cellEl.append('-');
          }

          return {
            getElement() {
              return cellEl;
            },
          };
        },
      },
    ],
    options: {
      columnOptions: {
        minWidth: 100,
        resizable: true,
      },
      pageOptions: {
        page: 1,
        perPage: 30,
        totalCount: 0,
      },
    },
    displayOptions: {
      showsRightArea: false,
      hasExcelDownloadButton: false,
      hasSettingButton: false,
      pageSizeKey: 'size',
      showPageNavigation: false,
    },
  };

  private readonly ipAccessControlRadioOption = ipAccessControlRadioOption;

  private get requiredInputsMap() {
    return new Map<string, TextInput | EmailInput>([
      ['PARTNER_NAME', this.partnerNameRef],
      ['COMPANY_NAME', this.companyNameRef],
      ['BUSINESS_REGISTRATION_NO', this.businessRegistrationNoRef],
      ['REPRESENTATIVE_NAME', this.representativeNameRef],
      ['BUSINESS_TYPE', this.businessTypeRef],
      ['BUSINESS_CONDITION', this.businessConditionRef],
      ['REPRESENTATIVE_PHONE_NO', this.representativePhoneNoRef],
      ['REPRESENTATIVE_EMAIL', this.representativeEmailRef],
      ['SETTLEMENT_MANAGE_NAME', this.settlementManageNameRef],
      ['SETTLEMENT_MANAGE_PHONE_NO', this.settlementManagePhoneNoRef],
      ['SETTLEMENT_MANAGEr_EMAIL', this.settlementManagerEmailRef],
      ['TRADE_BANK_ACCOUNT', this.tradeBankAccountRef],
      ['TRADE_BANK_DEPOSITOR_NAME', this.tradeBankDepositorNameRef],
    ]);
  }
  private get isDomestic() {
    return this.partnerInfo.countryCode === 'KR';
  }

  private partnerInfo = partnerModificationInitialState;
  private isTradeBankEditMode = false;
  private handleTradeBankEditMode(state: boolean) {
    this.isTradeBankEditMode = !state;
  }

  private contracts: Contracts[] = [];
  private contractsTotalCount = 0;

  // 대표 운영자 > 운영자 IP 접속제한
  private useIpAccessControl = false;
  private permittedIpAddress = '';

  private resetPermittedIpAddresses(useIpAddressControl: boolean) {
    if (useIpAddressControl) return;
    this.useIpAccessControl = useIpAddressControl;
    this.permittedIpAddress = '';
    this.partnerInfo.masterAdmin.permittedIpAddresses = [];
  }

  private setPermittedIpAddress({ masterAdmin }: PartnersInfoByNo) {
    this.useIpAccessControl = masterAdmin?.permittedIpAddresses?.length > 0;
    if (this.useIpAccessControl) {
      this.permittedIpAddress = masterAdmin?.permittedIpAddresses.join('\n');
    }
  }

  private resendEmailToPartner() {
    resendEmail(this.partnerInfo.partnerNo);
  }

  private checkTradeBankName() {
    if (this.partnerInfo.tradeBank.bank === 'DIRECT') {
      this.resetAccountInfo();
    }
  }
  private resetAccountInfo() {
    this.partnerInfo.tradeBank.bankName = '';
    this.partnerInfo.tradeBank.account = '';
    this.partnerInfo.tradeBank.depositorName = '';
  }

  private focusEmptyInput(name: string, ref: TextInput | EmailInput) {
    alert(this.$i18n.t(`PARTNER.EDIT.ALERT_NO_${name}`));
    setTimeout(() => ref.focus());
    return true;
  }

  // 파트너 수정 로직
  private hasAllRequiredInputValue() {
    const { phoneNo, email } = this.partnerInfo.csManager;
    let validAllRequiredInput = true;

    for (const [inputName, inputRef] of this.requiredInputsMap) {
      if (inputRef && !inputRef.$props.value.trim()) {
        validAllRequiredInput = false;

        this.focusEmptyInput(inputName, inputRef);
        break;
      }
    }

    if (phoneNo.length && (phoneNo.match(/[\d-]/g)?.join('') !== phoneNo || phoneNo.length < 8)) {
      alert(this.$t('PARTNER.EDIT.CS_PHONE_NO_ALERT'));
      return false;
    }

    if (email.replace(/[\s‘”<>`(),:;\\[\]]/g, '') !== email) {
      alert(this.$t('PARTNER.EDIT.CS_EMAIL_ALERT'));
      return false;
    }

    return validAllRequiredInput;
  }

  // input 이외의 값 검증
  private isValidPartnerInfo() {
    // 파트너 노출여부
    if(this.isDependencyYn !== this.partnerInfo.dependencyYn && !confirm(`해당 파트너사의 노출 설정을 ‘${this.partnerInfo.dependencyYn ? '노출 안 함' : '노출함'}’으로 변경하시겠습니까?\n${this.partnerInfo.dependencyYn ? '노출 안 함 으로 설정 시 파트너 조회결과에 노출되지 않습니다' : '변경 시 파트너 검색/등록 시 조회결과에 노출됩니다.' }`) ) {
      return false;
    }

    // 운영자 IP 접속제한 사용함인 경우 IP 등록 필수
    if (this.useIpAccessControl && this.partnerInfo.masterAdmin.permittedIpAddresses.filter(Boolean).length < 1) {
      this.focusEmptyInput('MASTER_PERMITTED_IP_ADDRESS', this.masterAdminPermittedIpAddressRef);
      return false;
    }

    const gridData = this.tradeMallGridRef.getData();
    for (let i = 0; i < gridData.length; i++) {
      if (gridData[i].usesDefaultCommissionRate && !gridData[i].pureCommissionRate) {
        alert(this.$t('PARTNER.EDIT.ALERT_NO_COMMISSION_RATE'));
        return false;
      }
    }

    return true;
  }

  private putPartnerInfo() {
    const doModifyPartner = confirm(this.$i18n.t('PARTNER.EDIT.CONFIRM_SAVE').toString());
    if (doModifyPartner) {
      const foreignPartnerInfo = {
        ...this.partnerInfo,
        sellerTaxationType: null,
        business: { ...this.partnerInfo.business, businessCondition: null, type: null },
        faxNo: null,
      };
      const partnerInfo = this.isDomestic ? this.partnerInfo : foreignPartnerInfo;
      const request = putPartnersByPartnerNoRequest(this.partnerInfo.partnerNo, partnerInfo);
      return this.$api.putPartnersInfoByNo(request);
    }
  }

  private putContracts() {
    const gridData = this.tradeMallGridRef.getData() as unknown as Contracts[];
    const contractsData = this.getContractsData(gridData);

    const request = putContractsRequest(Number(this.partnerInfo.partnerNo), contractsData);
    return this.$api.putContracts(request);
  }

  private getContractsData(gridData: Contracts[]): PutContracts[] {
    return gridData.map(contractData => {
      return {
        commissionRate: contractData.pureCommissionRate,
        contractNo: contractData.contractNo,
        promotionAgreed: contractData.promotionAgreed,
        usesDefaultCommissionRate: contractData.usesDefaultCommissionRate,
        pureCommissionRate: contractData.pureCommissionRate,
      };
    });
  }

  private modify() {
    this.setFinalRequest();

    if (this.hasAllRequiredInputValue() && this.isValidPartnerInfo()) {
      this.putPartnerInfo()
        .then(() => {
          alert(this.$t('PARTNER.EDIT.NOTICE_SAVED'));
          this.putContracts();
          this.$router.push({ name: 'PartnerListPrev' });
        })
        .catch(error => {
          const isInvalidIP = error.data?.errors?.some(({ reason }) => reason.includes('Invalid IP'));

          if (isInvalidIP) {
            alert(this.$t('PARTNER.EDIT.PLZ_CHECK_PERMITTED_IP_ADDRESS'));
          }
        });
    }
  }

  private setFinalRequest() {
    this.setPermittedIpAddresses();
  }

  private setPermittedIpAddresses() {
    if (!this.useIpAccessControl) {
      this.resetPermittedIpAddresses(false);
      return;
    }
    const permittedIpAddresses = this.permittedIpAddress
      .split('\n')
      .map(v => v.trim())
      .filter(Boolean);

    this.partnerInfo.masterAdmin.permittedIpAddresses =
      permittedIpAddresses.length > 0 ? permittedIpAddresses : [this.permittedIpAddress];
  }

  // ***

  // 파트너 삭제 로직
  private deletePartner() {
    this.$api.deletePartnersPartnerNo(deletePartnerRequest(this.partnerInfo.partnerNo));
    this.$router.push({ name: 'PartnerListPrev' });
  }

  private delete = () => {
    const deleteMassage = this.$i18n.t('PARTNER.EDIT.CONFIRM_DELETE_PARTNER') as string;
    if (!confirm(deleteMassage)) return;

    this.deletePartner();
  };
  // ***

  private getFilteredMasterAdmin(adminInfo): MasterAdminInfo {
    const masterAdminInfo = Object.assign({}, adminInfo);
    Object.keys(masterAdminInfo).forEach(key => {
      if (key === 'permittedIpAddresses' || key === 'externalAccessEnabled') return;
      masterAdminInfo[key] = masterAdminInfo[key] || '-';
    });

    return masterAdminInfo;
  }

  private async reloadContracts() {
    const { partnerNo } = this.$route.params;

    const { data: contracts } = await this.$api.getContracts(getContractsRequest(Number(partnerNo)));
    this.contracts = contracts.contents;
    this.contractsTotalCount = contracts.totalCount;
  }

  private async init() {
    // 1. 파트너 조회
    const { partnerNo } = this.$route.params;
    const { partnerStatus } = this.$route.query;
    const { data: partnerInfo } = await this.$api.getPartnersInfoByNo(getPartnerInfoRequest(partnerNo));

    this.partnerInfo = {
      ...partnerInfo,
      tradeBank: {
        ...partnerInfo.tradeBank,
        bank: partnerInfo.tradeBank.bank ?? 'DIRECT',
      },
    };

    this.isDependencyYn = partnerInfo.dependencyYn;
    this.partnerInfo.masterAdmin = this.getFilteredMasterAdmin(partnerInfo.masterAdmin);

    // 운영자 IP 접속제한
    this.setPermittedIpAddress(partnerInfo);

    if (partnerStatus !== partnerInfo.partnerStatus) {
      this.$router.push({ query: { partnerStatus: partnerInfo.partnerStatus } }).then(() => {
        this.$router.go(0);
      });
      return;
    }

    // 2. 계약서류, 입점계약서 조회
    await this.reloadContracts();

    const buttons: NavButton[] = [];
    if (this.partnerInfo.partnerStatus !== 'ACTIVE') {
      buttons.push({
        type: 'right',
        key: 'deletePartner',
        color: 'black',
        text: i18n.t('PARTNER.EDIT.DELETE'),
      });
    }
    if (this.partnerInfo.partnerStatus !== 'WAITING') {
      buttons.splice(0, 0, {
        type: 'right',
        key: 'updatePartner',
        color: 'red',
        text: i18n.t('PARTNER.EDIT.SAVE'),
      });
    }

    throwBottomNavigation({
      buttons,
      onClick: (options) => {
        switch (options) {
          case 'updatePartner':
            this.modify();
            break;

          case 'deletePartner':
            this.delete();
            break;
        }
      }
    });
  }

  created() {
    this.init();
  }
}
