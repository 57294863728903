












































import { Component, Mixins } from 'vue-property-decorator';
import TermsMixin from '@/views/contents/configuration/basic/terms/TermsMixin.vue';
import { getTermsRadioBoxOption, TERMS_TYPE } from '@/const/contents/configuration/terms';
import RadioGroup from '@/components/common/RadioGroup.vue';
import Editor from '@/components/common/summernote/Editor.vue';

@Component({
  components: { Editor, RadioGroup },
})
export default class AboutUs extends Mixins(TermsMixin) {
  private radioOption = getTermsRadioBoxOption().getUsed(TERMS_TYPE.MALL_INTRODUCTION);

  created() {
    this.noticeList = [
      this.$t('CONFIGURATION.TERMS.NOTICE_ABOUT_US_0'),
      this.$t('CONFIGURATION.TERMS.NOTICE_ABOUT_US_1'),
    ];
    this.termsTypeList = [TERMS_TYPE.MALL_INTRODUCTION];

    this.throwBottomNavigation(true);
  }

  protected validate(): boolean {
    const terms = this.getTermsData(TERMS_TYPE.MALL_INTRODUCTION);
    const editor = this.getEditor(TERMS_TYPE.MALL_INTRODUCTION);

    if (terms.used && !terms.contents) {
      const title = this.$t('CONFIGURATION.TERMS.ABOUT_US');
      alert(this.$t('CONFIGURATION.TERMS.ALERT_EMPTY_CONTENTS', { title }));
      editor.focus();
      return false;
    }

    return true;
  }
}
