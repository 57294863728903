import moment from 'moment';
import { OptionData } from '@/helpers/type';
export const DefaultRequestParams = {
  mallNos: '',
  searchDateType: 'NONE',
  startYmd: moment().format('YYYY-MM-DD'),
  endYmd: moment().format('YYYY-MM-DD'),
  searchType: 'ALL',
  platformTypes: ['PC_WEB', 'MOBILE_WEB', 'MOBILE_APP'],
  orderStatusTypes: [
    'DEPOSIT_WAIT',
    'PAY_DONE',
    'PRODUCT_PREPARE',
    'DELIVERY_PREPARE',
    'DELIVERY_ING',
    'DELIVERY_DONE',
    'BUY_CONFIRM',
    'REFUND_DONE',
  ],
  taskMessageSearchType: 'ALL',
  taskMessageTargetType: 'PARTNER',
  deliveryType: 'PARCEL_DELIVERY',
  orderRegisterType: 'NORMAL',
  claimStatusTypes: [
    'CANCEL_NO_REFUND',
    'CANCEL_REQUEST',
    'CANCEL_PROC_REQUEST_REFUND',
    'CANCEL_PROC_WAITING_REFUND',
    'CANCEL_DONE',
    'RETURN_REQUEST',
    'RETURN_REJECT_REQUEST',
    'RETURN_PROC_BEFORE_RECEIVE',
    'RETURN_PROC_REQUEST_REFUND',
    'RETURN_PROC_WAITING_REFUND',
    'RETURN_REFUND_AMT_ADJUST_REQUESTED',
    'RETURN_NO_REFUND',
    'RETURN_DONE',
    'EXCHANGE_REQUEST',
    'EXCHANGE_REJECT_REQUEST',
    'EXCHANGE_PROC_BEFORE_RECEIVE',
    'EXCHANGE_PROC_REQUEST_PAY',
    'EXCHANGE_PROC_WAITING',
    'EXCHANGE_PROC_WAITING_PAY',
    'EXCHANGE_DONE_PAY_DONE',
    'EXCHANGE_PROC_REQUEST_REFUND',
    'EXCHANGE_PROC_WAITING_REFUND',
    'EXCHANGE_DONE_REFUND_DONE',
    'EXCHANGE_DONE',
  ],
  shippingAreaType: 'PARTNER_SHIPPING_AREA',
  includeReservation: true,
  payTypes: [
    'CREDIT_CARD',
    'PAYCO',
    'VIRTUAL_ACCOUNT',
    'REALTIME_ACCOUNT_TRANSFER',
    'ESCROW_VIRTUAL_ACCOUNT',
    'ESCROW_REALTIME_ACCOUNT_TRANSFER',
    'MOBILE',
    'POINT',
    'ACCUMULATION',
    'ZERO_PAY',
    'NAVER_PAY',
    'YPAY',
    'KPAY',
    'PAYPIN',
    'INIPAY',
    'ACCOUNT',
    'PHONE_BILL',
    'GIFT',
    'ATM',
    'TOASTCAM',
    'ETC',
    'VERITRANS_CARD',
    'PAYPAL',
    'STRIPE',
    'APP_CARD',
  ],
};

/*
 * ncp-client-service의 OrderContentTop.vue에 선언된 private 객체들 모두 이곳에 재정리
 * ORDER_ORDER_CONTENT_TOP_ORDER_REGISTER_NORMAL 이런거 일일이 찾아서 역변환하기 너무 힘든데
 * */
export const OrderStates: string[] = [
  'DEPOSIT_WAIT',
  'PAY_DONE',
  'PRODUCT_PREPARE',
  'DELIVERY_PREPARE',
  'DELIVERY_ING',
  'DELIVERY_DONE',
  'BUY_CONFIRM',
  'REFUND_DONE',
];
export const ClaimStates: string[] = [
  'CANCEL_NO_REFUND',
  'CANCEL_REQUEST',
  'CANCEL_PROC_REQUEST_REFUND',
  'CANCEL_PROC_WAITING_REFUND',
  'CANCEL_DONE',
  'RETURN_REQUEST',
  'RETURN_REJECT_REQUEST',
  'RETURN_PROC_BEFORE_RECEIVE',
  'RETURN_PROC_REQUEST_REFUND',
  'RETURN_PROC_WAITING_REFUND',
  'RETURN_DONE',
  'EXCHANGE_REQUEST',
  'EXCHANGE_REJECT_REQUEST',
  'EXCHANGE_PROC_BEFORE_RECEIVE',
  'EXCHANGE_PROC_REQUEST_PAY',
  'EXCHANGE_PROC_WAITING_PAY',
  'EXCHANGE_DONE_PAY_DONE',
  'EXCHANGE_PROC_REQUEST_REFUND',
  'EXCHANGE_PROC_WAITING_REFUND',
  'EXCHANGE_DONE_REFUND_DONE',
  'EXCHANGE_DONE',
];

export const PayTypes: OptionData<string>[] = [
  // {value: 'ALL', name: '전체선택'},
  { value: 'CREDIT_CARD', name: '신용카드' },
  { value: 'PAYCO', name: 'PAYCO' },
  { value: 'VIRTUAL_ACCOUNT', name: '가상계좌' },
  { value: 'REALTIME_ACCOUNT_TRANSFER', name: '실시간계좌이체' },
  { value: 'ESCROW_VIRTUAL_ACCOUNT', name: '가상계좌-에스크로' },
  {
    value: 'ESCROW_REALTIME_ACCOUNT_TRANSFER',
    name: '실시간계좌이체-에스크로',
  },
  { value: 'MOBILE', name: '휴대폰결제' },
  { value: 'POINT', name: '포인트결제' },
  { value: 'ACCUMULATION', name: '적립금 전액 사용' },
  { value: 'ZERO_PAY', name: '0원결제' },
  { value: 'NAVER_PAY', name: '네이버페이 주문형' },
  { value: 'YPAY', name: '옐로페이' },
  { value: 'KPAY', name: '케이페이' },
  { value: 'PAYPIN', name: '페이핀' },
  { value: 'INIPAY', name: 'INIPay 간편결제' },
  { value: 'ACCOUNT', name: '무통장입금' },
  { value: 'PHONE_BILL', name: '전화결제' },
  { value: 'GIFT', name: '상품권' },
  { value: 'ATM', name: 'ATM' },
  { value: 'TOASTCAM', name: '토스트캠' },
  { value: 'ETC', name: '기타결제수단' },
  { value: 'VERITRANS_CARD', name: 'Veritrans CreditCard' },
  { value: 'PAYPAL', name: 'PAYPAL' },
  { value: 'STRIPE', name: 'STRIPE' },
];
export const PlatformTypes: OptionData<string>[] = [
  { value: 'PC_WEB', name: 'PC' },
  { value: 'MOBILE_WEB', name: '모바일웹' },
  { value: 'MOBILE_APP', name: '모바일앱' },
];
export const PlatformTypes2: OptionData<string>[] = [
  { name: '플랫폼 구분', value: 'ALL' },
  { name: 'PC웹', value: 'PC' },
  { name: '모바일웹', value: 'MOBILE_WEB' },
];

export const orderStatusTypes: OptionData<string>[] = [
  { value: 'DEPOSIT_WAIT', name: '입금대기' },
  { value: 'PAY_DONE', name: '결제완료' },
  { value: 'PRODUCT_PREPARE', name: '상품준비중' },
  { value: 'DELIVERY_PREPARE', name: '배송준비중' },
  { value: 'DELIVERY_ING', name: '배송중' },
  { value: 'DELIVERY_DONE', name: '배송완료' },
  { value: 'BUY_CONFIRM', name: '구매확정' },
  { value: 'REFUND_DONE', name: '환불완료' },
];
export const OrderRegisterTypes: OptionData<string>[] = [
  { value: 'NORMAL', name: '일반주문' },
  { value: 'ADMIN', name: '수기주문' },
  { value: 'ARIEL', name: '외부연계주문' },
];
export const SearchDateTypes: OptionData<string>[] = [
  { value: 'NONE', name: '전체' },
  { value: 'ORDER_YMDT', name: '주문일시' },
  { value: 'RESERVATION_START_YMDT', name: '예약판매시작일' },
  { value: 'STATE_CHANGE_YMDT', name: '상태변경일시' },
];
export const SearchTypes: OptionData<string>[] = [
  { value: 'ALL', name: '전체' },
  { value: 'ORDER_NO', name: '주문번호' },
  { value: 'ORDERER_NAME', name: '주문자명' },
  { value: 'MEMBER_NO', name: '회원번호' },
  { value: 'MALL_PRODUCT_NO', name: '상품번호' },
  { value: 'RECOMMENDER_ID', name: '추천인' },
  { value: 'ORDERER_CONTACT1', name: '주문자연락처' },
  { value: 'HS_CODE', name: 'HS CODE' },
  { value: 'PRODUCT_MANAGEMENT_CD', name: '상품관리코드' },
  { value: 'PRODUCT_NAME', name: '상품명' },
  { value: 'PRODUCT_NAME_EN', name: '영문상품명' },
  { value: 'OPTION_MANAGEMENT_CD', name: '옵션관리코드' },
  { value: 'OPTION_VALUE', name: '옵션값' },
  { value: 'DELIVERY_NO', name: '배송번호' },
  { value: 'INVOICE_NO', name: '송장번호' },
  { value: 'RECEIVER_NAME', name: '수령자명' },
  { value: 'RECEIVER_CONTACT1', name: '수령자연락처' },
  { value: 'NAVER_PAY_ORDER_NO', name: '네이버페이 주문번호' },
];
export const DeliveryTypes: OptionData<string>[] = [
  { value: 'PARCEL_DELIVERY', name: '택배/등기/소포' },
  { value: 'DIRECT_DELIVERY', name: '직접배송(화물배달)' },
];

export const ShippingAreaTypes: OptionData<string>[] = [
  { value: 'PARTNER_SHIPPING_AREA', name: '파트너사 배송' },
  { value: 'MALL_SHIPPING_AREA', name: '쇼핑몰 배송' },
];

export const ClaimStatusTypes: OptionData<string>[] = [
  { value: 'CANCEL_DONE', name: '취소' },
  { value: 'RETURN_DONE', name: '반품' },
  { value: 'EXCHANGE_DONE', name: '교환' },
];

export const TaskMessageSearchTypes: OptionData<string>[] = [
  { value: 'ALL', name: '전체' },
  { value: 'PROCESSING', name: '처리중' },
  { value: 'EXCLUDE_PROCESSING', name: '처리중 제외' },
];
export const PeriodSearchTypes = [
  { value: 'REGISTER_DATE', name: '상품등록일' },
  { value: 'PRE_SALE_START_DATE', name: '예약판매시작일' },
  { value: 'PRE_SALE_END_DATE', name: '예약판매종료일' },
  { value: 'SALE_START_DATE', name: '판매시작일' },
  { value: 'SALE_END_DATE', name: '판매종료일' },
  { value: 'PRODUCT_MODIFIED_DATE', name: '최종수정일' },
];
export const DisplableTypes = [
  { name: '노출여부', value: 'ALL' },
  { name: '노출함', value: 'Y' },
  { name: '노출 안 함', value: 'N' },
];
