
































import { Vue, Component, Prop } from 'vue-property-decorator';
import { unescapeHtml } from '@/utils/common';
import { CosBoardsBoardCodeSummary } from 'ncp-api-supporter';

interface Article {
  date: string;
  link: string;
  title: string;
  newArticle: boolean;
}

@Component
export default class ArticleList extends Vue {
  @Prop({ required: true })
  private readonly title!: string;
  @Prop({ required: true })
  private readonly detailLink!: string;
  @Prop({ required: true })
  private readonly articles!: CosBoardsBoardCodeSummary[];
  private unescapeHtml = unescapeHtml;

  private get commerceSupportUrl() {
    return `${process.env.VUE_APP_COMMERCE_MAIN}/support/board`
  }
}
