


























































































import { Component, Mixins } from 'vue-property-decorator';
import TermsMixin from '@/views/contents/configuration/basic/terms/TermsMixin.vue';
import { getTermsRadioBoxOption, pITypeList, TERMS_TYPE } from '@/const/contents/configuration/terms';
import RadioGroup from '@/components/common/RadioGroup.vue';
import Editor from '@/components/common/summernote/Editor.vue';
import { TermsType } from 'ncp-api-supporter';

@Component({ components: { Editor, RadioGroup } })
export default class PersonalInformation extends Mixins(TermsMixin) {
  private getTermsTitle(termsType: TermsType, prefixNecessary: boolean): string {
    let prefix = '';
    switch (termsType) {
      case TERMS_TYPE.PI_COLLECTION_AND_USE_REQUIRED:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.NECESSARY') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.PERSONAL_INFORMATION_COLLECTION_AND_USE');
      case TERMS_TYPE.PI_COLLECTION_AND_USE_OPTIONAL:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.SELECT') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.PERSONAL_INFORMATION_COLLECTION_AND_USE');
      case TERMS_TYPE.PI_PROCESS_CONSIGNMENT:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.SELECT') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.PERSONAL_INFORMATION_PROCESS_CONSIGNMENT');
      case TERMS_TYPE.PI_THIRD_PARTY_PROVISION:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.SELECT') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.PERSONAL_INFORMATION_THIRD_PARTY_PROVISION');
      case TERMS_TYPE.PI_COLLECTION_AND_USE_ON_ORDER:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.NECESSARY') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.PERSONAL_INFORMATION_COLLECTION_AND_USE');
      case TERMS_TYPE.PI_SELLER_PROVISION:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.NECESSARY') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.PI_SELLER_PROVISION');
      case TERMS_TYPE.ORDER_INFO_AGREE:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.NECESSARY') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.ORDER_INFO_AGREE');
      case TERMS_TYPE.CLEARANCE_INFO_COLLECTION_AND_USE:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.SELECT') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.CLEARANCE_INFO_COLLECTION_AND_USE');
      case TERMS_TYPE.TRANSFER_AGREE:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.SELECT') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.TRANSFER_AGREE');
      case TERMS_TYPE.PI_COLLECTION_AND_USE_FOR_GUEST_ON_ARTICLE:
        if (prefixNecessary) {
          prefix = this.$t('CONFIGURATION.TERMS.NECESSARY') as string;
        }
        return prefix + this.$t('CONFIGURATION.TERMS.PERSONAL_INFORMATION_COLLECTION_AND_USE');
    }
    return '';
  }

  private radioOption = getTermsRadioBoxOption();

  // '사용여부' 항목 노출여부를 판단하는 메소드임
  private isShowUsage(termsType): boolean {
    switch (termsType) {
      // 아래 항목들은 필수값들이기에 사용여부를 노출하지 않음
      case TERMS_TYPE.PI_COLLECTION_AND_USE_REQUIRED:
      case TERMS_TYPE.PI_COLLECTION_AND_USE_ON_ORDER:
      case TERMS_TYPE.PI_SELLER_PROVISION:
      case TERMS_TYPE.ORDER_INFO_AGREE:
      case TERMS_TYPE.PI_COLLECTION_AND_USE_FOR_GUEST_ON_ARTICLE:
        return false;
    }
    return true;
  }

  created() {
    this.noticeList = [
      this.$t('CONFIGURATION.TERMS.NOTICE_PERSONAL_INFORMATION_0'),
      this.$t('CONFIGURATION.TERMS.NOTICE_PERSONAL_INFORMATION_1'),
      this.$t('CONFIGURATION.TERMS.NOTICE_PERSONAL_INFORMATION_2'),
    ];

    this.termsTypeList = pITypeList;

    this.throwBottomNavigation();
  }

  mounted() {
    const editor = this.getEditor(this.termsTypeList[0]);
    editor.focus();
  }

  protected validate(): boolean {
    for (const termsType of this.termsTypeList) {
      const terms = this.getTermsData(termsType);
      if ((!this.isShowUsage(termsType) || (this.isShowUsage(termsType) && terms.used)) && !terms.contents) {
        const editor = this.getEditor(termsType);
        const title = this.getTermsTitle(termsType, false);
        alert(this.$t('CONFIGURATION.TERMS.ALERT_EMPTY_CONTENTS', { title }));
        editor.focus();
        return false;
      }
    }

    return true;
  }
}
