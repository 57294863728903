










































import { Component, Vue, Watch } from 'vue-property-decorator';
import { MENU_AUTHORITY_TYPE, MenuDataType } from '@/const/contents/configuration/authorityGroup';
import AuthorityGroupModifyBasic from '@/views/contents/configuration/management/authorityGroupChild/AuthorityGroupModifyBasic.vue';
import AuthorityGroupModifyMenu from '@/views/contents/configuration/management/authorityGroupChild/AuthorityGroupModifyMenu.vue';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { GetAuthorityGroupsByGroupNoResponse, PreviousOrdersMall } from 'ncp-api-supporter';
import { Getter } from 'vuex-class';
import TextInput from '@/components/common/input/TextInput.vue';
import ReasonDetailLayerPopup from '@/components/ReasonDetailLayerPopup.vue';

enum MODIFY_MODE {
  REGISTER = 'REGISTER',
  EDIT = 'EDIT',
}

export type AuthorityGroupsCustomType = GetAuthorityGroupsByGroupNoResponse & { reasonDetail: string }

@Component({ components: { AuthorityGroupModifyBasic, AuthorityGroupModifyMenu, TextInput, ReasonDetailLayerPopup } })
export default class AuthorityGroupModify extends Vue {
  // TODO 이전주문 리스트 메뉴 노출 여부는 추후 api 값으로 변경될 예정입니다.
  @Getter('mall/getPreviousMalls') private previousMalls!: PreviousOrdersMall[];

  private mode = MODIFY_MODE.REGISTER;

  private groupNo = -1;

  private getDefaultData = (): AuthorityGroupsCustomType => {
    return {
      authorityGroupNo: -1,
      name: '',
      description: '',
      allowsMarketingDirection: false,
      permitsPrivateInformation: false,
      menuAuthorities: [],
      mallNos: [],
      smsReceived: false,
      adminCount: 0,
      reasonDetail: '',
    };
  };

  private originData: AuthorityGroupsCustomType = this.getDefaultData();
  private data: AuthorityGroupsCustomType = this.getDefaultData();

  private menuList: MenuDataType[] = [];
  private prevMenuAuthorityTypeMap: Map<string, MENU_AUTHORITY_TYPE> = new Map();
  private originMenuAthority = [];

  private isChangeData = false;
  private reasonDetailModal = { 
    type: 'EDIT',
    isOpen: false,
    reasonDetail: '',
   };

  private isEditMode(): boolean {
    return this.mode === MODIFY_MODE.EDIT;
  }

  @Watch('$route')
  private init() {
    const mode = this.$route.path.includes('authority-group/edit') ? MODIFY_MODE.EDIT : MODIFY_MODE.REGISTER;
    const groupNo = Number(this.$route.query.groupNo);

    if (this.mode === mode && groupNo === this.groupNo) {
      return;
    }

    this.mode = mode;
    this.groupNo = groupNo;

    const arrPromise = [this.getMenu()];

    if (this.groupNo > 0) {
      arrPromise.push(this.getAuthorityGroupsByGroupNo());
    }

    Promise.all(arrPromise).then(() => {
      if (this.groupNo > 0) {
        this.applyDataToMenuList();
      }
    });

    this.throwBottomNavigation();
  }

  created() {
    this.init();
  }

  // get authority group info by groupNo
  private getAuthorityGroupsByGroupNo(): Promise<void> {
    return this.$api
      .getAuthorityGroupsByGroupNo({
        pathParams: {
          authorityGroupNo: this.groupNo + '',
        },
      })
      .then(response => {
        if (response && response.status === 200) {
          if (!this.isEditMode() && this.groupNo > 0) {
            response.data.mallNos = [];
          }

          this.data = { ...response.data, reasonDetail: '', };
          this.originData = { ...response.data, reasonDetail: '' };
        }
      })
      .catch();
  }

  // get all menu
  private getMenu(): Promise<void> {
    return this.$api.getMenu().then(response => {
      if (response && response.status === 200) {
        this.menuList = [];

        // TODO 이전주문 리스트 권한 설정 항목 노출 여부는 추후 api 값으로 변경될 예정입니다.
        // 이전주문몰이 없는 경우 '이전주문 리스트 권한 설정 항목' 미노출
        // newMenuList -> 삭제하고, response.data 를 바로 this.makeMenuList params 로 넘겨야함.
        // api 개발완료시점에 삭제될 부분이라 다국어와 타입 처리 생략하겠습니다.
        // start
        const newMenuList = response.data.map(d => {
          if (d.name === '주문/배송') {
            d.menus = d.menus.map((m: any) => {
              if (m.name === '주문 관리' && !this.previousMalls.length) {
                m.menus = m.menus.filter(({ name }) => !name.includes('이전주문'));
              }
              return m;
            });
          }
          return d;
        });
        // end

        this.makeMenuList(newMenuList);
        this.mapPrevAuthorityMenuTypes();
      }
    });
  }

  // delete authority group
  private deleteAuthorityGroupsByGroupNo(): Promise<boolean> {
    return this.$api
      .deleteAuthorityGroupsByGroupNo({
        pathParams: {
          authorityGroupNo: this.groupNo + '',
        },
        params: {
          reasonDetail: this.reasonDetailModal.reasonDetail,
        }
      })
      .then(response => response && response.status === 204)
      .catch(() => false);
  }

  // post authority group
  private postAuthorityGroups(): Promise<boolean> {
    const request = {
      data: {
        name: this.data.name,
        description: this.data.description,
        allowsMarketingDirection: this.data.allowsMarketingDirection,
        permitsPrivateInformation: this.data.permitsPrivateInformation,
        smsReceived: this.data.smsReceived,
        mallNos: this.data.mallNos,
        reasonDetail: this.data.reasonDetail,
        menuAuthorities: this.menuList
          .filter(m => !m.children || m.children.length <= 0)
          .map(menu => {
            return {
              menuNo: menu.menuNo,
              readable:
                menu.authorityType === MENU_AUTHORITY_TYPE.READ ||
                menu.authorityType === MENU_AUTHORITY_TYPE.READ_WRITE,
              writable: menu.authorityType === MENU_AUTHORITY_TYPE.READ_WRITE,
            };
          }),
      },
    };

    return this.$api
      .postAuthorityGroups(request)
      .then(response => response && response.status === 201)
      .catch(() => false);
  }

  // put authority group
  private putAuthorityGroupsByGroupNo(): Promise<boolean> {
    const request = {
      pathParams: {
        authorityGroupNo: this.groupNo + '',
      },
      data: {
        name: this.data.name,
        description: this.data.description,
        allowsMarketingDirection: this.data.allowsMarketingDirection,
        permitsPrivateInformation: this.data.permitsPrivateInformation,
        smsReceived: this.data.smsReceived,
        mallNos: this.data.mallNos,
        reasonDetail: this.reasonDetailModal.reasonDetail,
        menuAuthorities: this.menuList
          .filter(m => !m.children || m.children.length <= 0)
          .map(menu => {
            return {
              menuNo: menu.menuNo,
              readable:
                menu.authorityType === MENU_AUTHORITY_TYPE.READ ||
                menu.authorityType === MENU_AUTHORITY_TYPE.READ_WRITE,
              writable: menu.authorityType === MENU_AUTHORITY_TYPE.READ_WRITE,
            };
          }),
      },
    };

    return this.$api
      .putAuthorityGroupsByGroupNo(request)
      .then(response => response && response.status === 204)
      .catch(() => false);
  }

  // make menu list data (initialize)
  private makeMenuList(targetList: any[], parent?: MenuDataType, depth = 0): void {
    targetList.map(m => {
      const menu = {
        menuNo: m.menuNo ? m.menuNo : `${m.name}_${m.order}_${new Date().getTime()}`,

        name: m.name,
        order: m.order,

        readable: m.readable ?? false,
        writable: m.writable ?? false,

        authorityType: MENU_AUTHORITY_TYPE.NONE,

        parent: parent,
        children: [],

        checked: false,
        depth: depth,
        show: false,
        isProductMenu: false,
      };

      if (m.writable) {
        menu.authorityType = MENU_AUTHORITY_TYPE.READ_WRITE;
      } else if (m.readable) {
        menu.authorityType = MENU_AUTHORITY_TYPE.READ;
      }

      if (parent && parent.children) {
        parent.children.push(menu);
      }

      if (depth === 0) {
        menu.show = true;
      }

      if (menu.menuNo === 388 || menu.menuNo === 8546) {
        menu.parent.isProductMenu = true;
      }

      if (menu.name === this.$t('PRODUCT')) {
        menu.isProductMenu = true;
      }

      this.menuList.push(menu);

      if (m.menus && m.menus.length > 0) {
        this.makeMenuList(m.menus, menu, depth + 1);
      }
    });
  }

  private mapPrevAuthorityMenuTypes() {
    this.menuList
      .filter(({ children }) => children && children.length > 0)
      .forEach(({ menuNo, depth, authorityType }) => {
        this.prevMenuAuthorityTypeMap.set(`${menuNo}-${depth}`, authorityType);
      });
  }

  // apply data to menu list data
  private applyDataToMenuList(): void {
    this.data.menuAuthorities.map(dm => {
      const menu = this.menuList.filter(m => m.menuNo === dm.menuNo)[0];
      if (menu) {
        if (dm.writable) {
          menu.authorityType = MENU_AUTHORITY_TYPE.READ_WRITE;
        } else if (dm.readable) {
          menu.authorityType = MENU_AUTHORITY_TYPE.READ;
        } else {
          menu.authorityType = MENU_AUTHORITY_TYPE.NONE;
        }
      }
    });

    this.menuList
      .filter(m => !m.parent)
      .map(m => {
        this.setParentMenuAuthority(m);
      });

    this.originMenuAthority = this.menuList.map(m => m.authorityType);
  }

  // set menu auth (for parent menu auth init)
  private setParentMenuAuthority(menu: MenuDataType): void {
    if (!menu.children || menu.children.length <= 0) {
      return;
    }

    let childAuthorityType = null;
    let isSame = true;

    for (let i = 0; i < menu.children.length; i++) {
      const childMenu = menu.children[i];

      this.setParentMenuAuthority(childMenu);

      if (childAuthorityType === null) {
        childAuthorityType = childMenu.authorityType;
      }

      if (childAuthorityType !== childMenu.authorityType) {
        isSame = false;
      }
    }

    if (isSame) {
      menu.authorityType = childAuthorityType;
    } else {
      menu.authorityType = MENU_AUTHORITY_TYPE.INDIVIDUAL_SETTING_SUB_MENU;
    }

    this.mapPrevAuthorityMenuTypes();
  }

  // reset menu authority
  private resetMenuAuthority(): void {
    if (!confirm(this.$t('CONFIGURATION.AUTHORITY_GROUP.CONFIRM_RESET_MENU_AUTHORITY') as string)) {
      return;
    }

    this.menuList.map((m, index) => {
      m.authorityType = this.originMenuAthority[index] ?? MENU_AUTHORITY_TYPE.NONE;
    });

    this.menuList
      .filter(m => !m.parent)
      .map(m => {
        this.setParentMenuAuthority(m);
      });

    alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_COMPLETE_RESET_MENU_AUTHORITY'));
  }

  private changeData(isNotChanged): void {
    this.isChangeData = !isNotChanged;
  }

  // delete authority group
  private deleteAuthorityGroup(): void {
    if (!confirm(this.$t('CONFIGURATION.AUTHORITY_GROUP.CONFIRM_DELETE') as string)) {
      return;
    }

    this.deleteAuthorityGroupsByGroupNo().then(success => {
      if (success) {
        alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_SUCCESS_DELETE'));
        this.$router.back();
      }
    });
  }

  private closeReasonDetailModal() {
    this.reasonDetailModal.isOpen = false;
  }

  private openReasonDetailModal(type: 'EDIT' | 'DELETE') {
    if (!this.isEditMode()) {
      this.saveAuthorityGroup();

      return;
    }

    if (type === 'DELETE' && this.data.adminCount) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_CAN_NOT_DELETE'));

      return;
    }

    this.reasonDetailModal.isOpen = true;
    this.reasonDetailModal.type = type;
  }

  private confirmReasonDetailModal(value: string) {
    this.reasonDetailModal.reasonDetail = value;
    this.data.reasonDetail = value;

    if (!this.reasonDetailModal.reasonDetail) {
      alert('사유를 입력해주세요');
      
      return;
    }

    this.closeReasonDetailModal();

    if (this.reasonDetailModal.type === 'EDIT') {
      this.saveAuthorityGroup();

      return;
    } 

    this.deleteAuthorityGroup();
  }

  // add / edit authority group
  private saveAuthorityGroup(): void {
    if (!(this.$refs.modifyBasic as AuthorityGroupModifyBasic).validate()) {
      return;
    }

    let isAllNone = true;
    for (let i = 0; i < this.menuList.length; i++) {
      if (
        this.menuList[i].authorityType !== MENU_AUTHORITY_TYPE.NONE &&
        this.menuList[i].authorityType !== MENU_AUTHORITY_TYPE.INDIVIDUAL_SETTING_SUB_MENU
      ) {
        isAllNone = false;
        break;
      }
    }
    if (isAllNone) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_CHECK_MENU_AUTHORITY'));
      return;
    }

    if (this.isEditMode()) {
      this.putAuthorityGroupsByGroupNo().then(success => {
        if (success) {
          alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_SUCCESS_SAVE'));
          this.$router.push('/configuration/management/authority-group');
        }
      });
    } else {
      this.postAuthorityGroups().then(success => {
        if (success) {
          alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_SUCCESS_SAVE'));
          this.$router.push('/configuration/management/authority-group');
        }
      });
    }
  }

  // bottom button logic
  private throwBottomNavigation() {
    const buttons = [];

    if (this.isEditMode()) {
      buttons.push({
        type: 'left',
        key: 'copy-register',
        color: 'white',
        width: '150px',
        text: this.$t('CONFIGURATION.AUTHORITY_GROUP.COPY_REGIST'),
      });
      buttons.push({
        type: 'left',
        key: 'delete',
        color: 'white',
        text: this.$t('CONFIGURATION.AUTHORITY_GROUP.DELETE'),
      });
    }
    buttons.push({
      type: 'right',
      key: 'list',
      color: 'gray',
      text: this.$t('LIST'),
    });
    buttons.push({
      type: 'right',
      key: 'save',
      color: 'red',
      text: this.$t('SAVE'),
    });

    throwBottomNavigation({
      buttons,
      onClick: (key: string) => {
        switch (key) {
          case 'copy-register':
            if (!confirm(this.$t('CONFIGURATION.AUTHORITY_GROUP.CONFIRM_MOVE_COPY_REGIST') as string)) {
              return;
            }
            this.$router.push(`/configuration/management/authority-group/register?groupNo=${this.groupNo}`);
            break;
          case 'delete':
            this.openReasonDetailModal('DELETE');
            break;
          case 'list':
            if (this.isChangeData && !confirm(this.$t('CONFIGURATION.AUTHORITY_GROUP.CONFIRM_MOVE_LIST') as string)) {
              return;
            }
            this.$router.push('/configuration/management/authority-group');
            break;
          case 'save':
            this.openReasonDetailModal('EDIT');
            break;
        }
      },
    });
  }
}
