import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import BasicInfoPrev from '@/views/contents/configuration/basic/BasicInfo.vue';
import BasicInfoIframe from '@/views/contents/configuration/basic/BasicInfoIframe.vue';
import ExternalService from '@/views/contents/configuration/basic/ExternalServiceIframe/ExternalServiceIframe.vue';
import MallList from '@/views/contents/configuration/basic/MallList.vue';
import MallCreate from '@/views/contents/configuration/basic/MallCreate.vue';
import TermsIframe from '@/views/contents/configuration/basic/TermsIframe.vue';
import Terms from '@/views/contents/configuration/basic/Terms.vue';
import ShippingCharge from '@/views/contents/configuration/delivery/ShippingCharge.vue';
import Admin from '@/views/contents/configuration/management/Admin.vue';
import AuthorityGroup from '@/views/contents/configuration/management/AuthorityGroup.vue';
import PaycoSetting from '@/views/contents/configuration/payment/PaycoSetting.vue';
import PgSetting from '@/views/contents/configuration/payment/PgSetting.vue';
import PgSettingIframe from '@/views/contents/configuration/payment/PgSettingIframe.vue';
import OperateSecurity from '@/views/contents/configuration/security/OperateSecurity.vue';
import OperateSecurityIframe from '@/views/contents/configuration/security/OperateSecurityIframe.vue';
import AuthorityGroupModify from '@/views/contents/configuration/management/AuthorityGroupModify.vue';
import AdminEdit from '@/views/contents/configuration/management/AdminEdit.vue';
import ShippingChargeTemplate from '@/views/contents/configuration/delivery/ShippingChargeTemplate.vue';
import ShippingChargeAreaFee from '@/views/contents/configuration/delivery/ShippingChargeAreaFee.vue';
import ShippingChargeWarehouse from '@/views/contents/configuration/delivery/ShippingChargeWarehouse.vue';
import PersonalAccessHistory from '@/views/contents/configuration/security/PersonalAccessHistory.vue';
import PersonalAccessHistoryIframe from '@/views/contents/configuration/security/PersonalAccessHistoryIframe.vue';
import DomainSslIframe from '@/views/contents/configuration/basic/domainSsl/DomainSslIframe.vue';
import DomainDetailIframe from '@/views/contents/configuration/basic/domainSsl/DomainDetailIframe.vue';
import SslDetailIframe from '@/views/contents/configuration/basic/domainSsl/SslDetailIframe.vue';
import SecurityServer from '@/views/contents/configuration/security/SecurityServer.vue';
import NaverpayConfigIframe from '@/views/contents/configuration/payment/NaverpayConfigIframe.vue';
import OrderExtraConfigIframe from '@/views/contents/configuration/payment/OrderExtraConfigIframe.vue';

export default (): RouteConfig => {
  return {
    path: '/configuration',
    name: 'configuration',
    component: DefaultLayout,
    children: [
      {
        path: 'basic/info',
        name: 'BasicInfo',
        component: BasicInfoIframe,
        meta: {
          detection: true,
          breadcrumb: ['설정', '기본정책', '기본정보'],
        },
      },
      {
        path: 'basic/info-prev',
        name: 'BasicInfoPrev',
        component: BasicInfoPrev,
        meta: {
          detection: true,
          breadcrumb: ['설정', '기본정책', '기본정보'],
        },
      },
      {
        path: 'basic/external',
        name: 'ExternalService',
        component: ExternalService,
        meta: {
          detection: true,
          detectConfirm: true,
        },
      },
      {
        path: 'basic/malls',
        name: 'MallList',
        component: MallList,
      },
      //  :mallId? 를 이용하면 Create, Update를 하나의 라우터로 관리할 수 있다.
      //  페이지 타이틀이 달라져야 하는데 라우터 meta 안에서 :mallId에 접근할 수 있는 방법이 없다.
      //  어쩔 수 없이 두개의 라우터로 분리시킨다. 눈물 한스푼
      //    {
      //        path: 'basic/mall/:mallId?',
      //        name: 'MallCreate',
      //        component: MallCreate,
      //        meta: {
      //          breadcrumb: this.mallId ? ['설정','기본정책','쇼핑몰 수정'] :  ['설정','기본정책','쇼핑몰 등록']
      //        },
      //      },
      //
      {
        path: 'basic/mall',
        name: 'MallCreate',
        component: MallCreate,
        meta: {
          breadcrumb: ['설정', '기본정책', '쇼핑몰 등록'],
        },
      },
      {
        path: 'basic/mall/:mallId',
        name: 'MallCreate',
        component: MallCreate,
        meta: {
          breadcrumb: ['설정', '기본정책', '쇼핑몰 수정'],
        },
      },
      {
        path: 'basic/terms',
        name: 'TermsIframe',
        component: TermsIframe,
      },
      {
        path: 'basic/terms-prev',
        name: 'Terms',
        component: Terms,
      },
      {
        path: 'delivery/shipping-charge',
        name: 'ShippingCharge',
        component: ShippingCharge,
        redirect: { name: 'ShippingChargeTemplate' },
        children: [
          {
            path: 'template',
            name: 'ShippingChargeTemplate',
            component: ShippingChargeTemplate,
          },
          {
            path: 'area-fee',
            name: 'ShippingChargeAreaFee',
            component: ShippingChargeAreaFee,
          },
          {
            path: 'warehouse',
            name: 'ShippingChargeWarehouse',
            component: ShippingChargeWarehouse,
          },
        ],
      },
      {
        path: 'management/admin',
        name: 'Admin',
        component: Admin,
      },
      {
        path: 'management/admin/edit',
        name: 'AdminEdit',
        component: AdminEdit,
        meta: {
          breadcrumb: ['설정', '관리정책', '운영자 관리', '운영자 수정'],
        },
      },
      {
        path: 'management/authority-group',
        name: 'AuthorityGroup',
        component: AuthorityGroup,
      },
      {
        path: 'management/authority-group/register',
        name: 'AuthorityGroupRegister',
        component: AuthorityGroupModify,
        meta: {
          breadcrumb: ['설정', '관리정책', '권한그룹 관리', '권한그룹 등록'],
        },
      },
      {
        path: 'management/authority-group/edit',
        name: 'AuthorityGroupEdit',
        component: AuthorityGroupModify,
        meta: {
          breadcrumb: ['설정', '관리정책', '권한그룹 관리', '권한그룹 수정'],
        },
      },
      {
        path: 'payment/pg-prev',
        name: 'PgSetting',
        component: PgSetting,
      },
      {
        path: 'payment/pg',
        name: 'PgSettingIframe',
        component: PgSettingIframe,
      },
      {
        path: 'payment/order-extra-config',
        name: 'OrderExtraConfigIframe',
        component: OrderExtraConfigIframe,
      },
      // {
      //   path: 'payment/naver',
      //   name: 'NaverSetting',
      //   component: NaverSetting,
      // },   -> 네이버페이 주문형 홀딩
      {
        path: 'payment/payco',
        name: 'PaycoSetting',
        component: PaycoSetting,
      },
      {
        path: 'easy-payment/naver',
        name: 'NaverpayConfigIframe',
        component: NaverpayConfigIframe,
      },
      {
        path: 'security/setting-prev',
        name: 'OperateSecurity',
        component: OperateSecurity,
      },
      {
        path: 'security/setting',
        name: 'OperateSecurityIframe',
        component: OperateSecurityIframe,
      },
      {
        path: 'security/personal-access-history-prev',
        name: 'PersonalAccessHistoryPrev',
        component: PersonalAccessHistory,
        meta: {
          excelCreateMenuKey: 'PRO_PRIVACY_HISTORIES',
        },
      },
      {
        path: 'security/personal-access-history',
        name: 'PersonalAccessHistory',
        component: PersonalAccessHistoryIframe,
      },
      //TODO 삭제될  보안서버 메뉴
      {
        path: 'security/server',
        name: 'SecurityServer',
        component: SecurityServer,
        meta: { detection: true },
      },
      {
        path: 'basic/domain-ssl',
        name: 'DomainSslIframe',
        component: DomainSslIframe,
      },
      {
        path: 'basic/domain-detail/:domainNo',
        name: 'DomainDetailIframe',
        component: DomainDetailIframe,
        meta: {
          breadcrumb: ['설정', '기본정책', '도메인/보안서버 관리', '도메인 상세'],
        },
      },
      {
        path: 'basic/ssl-detail/:domainNo',
        name: 'SslDetailIframe',
        component: SslDetailIframe,
        meta: {
          breadcrumb: ['설정', '기본정책', '도메인/보안서버 관리', '보안서버 상세'],
        },
      },
    ],
  };
};
