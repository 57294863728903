






























































































/* eslint-disable @typescript-eslint/camelcase */
import { stringify } from 'qs';
import { Component, Vue } from 'vue-property-decorator';
import { AuthToken } from 'ncp-api-supporter';
import { moveStandardAdmin, setSessionCookie, setToken } from '@/utils/token';
import { deleteCookie, getCookie, setCookie } from '@/utils/cookie';
import { Mutation } from 'vuex-class';
import { fetchInitialData } from '@/router/hooks';
import { Admin } from '@/types';
import EmailInput from '@/components/common/input/EmailInput.vue';
import AuthPasswordChangePopup from '@/views/auth/authPopups/AuthPasswordChangePopup.vue';
import AuthPasswordChangeGuidePopup from '@/views/auth/authPopups/AuthPasswordChangeGuidePopup.vue';
import AuthPasswordChangeAfterLoginPopup from '@/views/auth/authPopups/AuthPasswordChangeAfterLoginPopup.vue';
import AuthSecondPopup from '@/views/auth/authPopups/AuthSecondPopupIframe.vue';
import { LOGIN_STATUS, SecondAuthData } from '@/const/contents/auth';
import AuthFindOtpKeyPopup from '@/views/auth/authPopups/AuthFindOtpKeyPopup.vue';
import { SESSION_INITIALIZE, SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN } from '@/const/cookie';

const { VUE_APP_COMMERCE_WEB_API, VUE_APP_REDIRECT_URL } = process.env;

@Component({
  components: {
    EmailInput,
    AuthPasswordChangePopup,
    AuthPasswordChangeGuidePopup,
    AuthPasswordChangeAfterLoginPopup,
    AuthSecondPopup,
    AuthFindOtpKeyPopup,
  },
})
export default class AuthMain extends Vue {
  @Mutation('admin/SET_ADMIN') private setAdmin!: (admin: Admin) => void;
  private planType = '';

  private id = '';
  private password = '';
  private rememberId = false;
  private isIntegratedAccount = false;
  private isLocal = process.env.VUE_APP_NODE_ENV === 'local';

  private lastLogin = '';
  private name = '';

  private openPopupName = '';

  private secondAuthData: SecondAuthData = {
    key: '',
    plan: '',
    target: {
      email: '',
      enableOtp: false,
      mobile: '',
    },
    type: '',
    types: [],
  };
  private secondAuthResolve?: Function;

  private rememberAdminId(value: boolean) {
    this.rememberId = value;
  }
  private async consolidatedLogin(query) {
    await this.setToken(query);
    if (this.planType === 'PRO' || this.planType === 'BASIC') {
      await fetchInitialData();
    }
    if (query?.redirectUrl && (this.planType === 'PRO' || this.planType === 'BASIC')) {
      this.$router.replace(query?.redirectUrl);
      return;
    }
    this.moveRoot();
  }
  created() {
    if (this.$route.query?.accessToken) {
      this.consolidatedLogin(this.$route.query);
    }

    if (getCookie(SHOPBY_ACCESSTOKEN)) {
      this.$router.replace('/');
    }

    if (!getCookie(SHOPBY_ACCESSTOKEN) && !getCookie(SHOPBY_REFRESHTOKEN)) {
      deleteCookie(SESSION_INITIALIZE);
    }

    const tempId = getCookie('rememberId');
    this.id = tempId ?? '';
    this.rememberAdminId(Boolean(tempId));
  }

  private postAuthToken(): Promise<LOGIN_STATUS> {
    const request = {
      data: {
        id: this.id,
        password: this.password,
        adminType: 'SERVICE',
        grantType: this.isLocal && this.isIntegratedAccount ? 'COMMERCE_ACCOUNTS' : 'PASSWORD',
      } as const,
    };

    return this.$api[this.isLocal && this.isIntegratedAccount ? 'postAuthToken' : 'postAuthLogin'](request)
      .then(response => {
        if (response) {
          if (response.status === 200) {
            this.setToken(response.data);

            return LOGIN_STATUS.SUCCESS;
          }
        }

        return LOGIN_STATUS.FAIL;
      })
      .catch(e => {
        if (e.data.status === 400 && e.data.code) {
          if (e.data.code.includes('AU1001')) {
            this.secondAuthData = {
              ...this.secondAuthData,
              ...e.data.body,
            };

            return LOGIN_STATUS.SECOND_AUTH;
          } else if (e.data.code.includes('AU1002')) {
            return LOGIN_STATUS.IP_BLOCK;
          } else if (e.data.code.includes('AU1003')) {
            return LOGIN_STATUS.NATION_BLOCK;
          } else if (e.data.code.includes('E0504')) {
            this.handleConsolidatedAuth();
            return LOGIN_STATUS.FAIL;
          }
        }

        return LOGIN_STATUS.FAIL;
      });
  }

  private openSearchPasswordPopup(): void {
    this.openPopup('AuthPasswordChangePopup');
  }

  private mustBeChanged = false;
  private login(): void {
    if (!this.id) {
      (this.$refs.id as HTMLElement).focus();
      alert(`${this.$t('ID')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    if (!this.password) {
      (this.$refs.password as HTMLElement).focus();
      alert(`${this.$t('PASSWORD')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    this.postAuthToken()
      .then(loginStatus => {
        if (loginStatus === LOGIN_STATUS.SECOND_AUTH) {
          return this.openSecondAuthPopup();
        } else if (loginStatus === LOGIN_STATUS.IP_BLOCK) {
          alert(this.$t('AUTH.ALERT_IP_BLOCK'));
          throw 'ip block';
        } else if (loginStatus === LOGIN_STATUS.NATION_BLOCK) {
          throw 'nation block';
        }

        return loginStatus;
      })
      .then(loginStatus => {
        if (loginStatus === LOGIN_STATUS.SUCCESS) {
          if (this.planType === 'PRO') {
            return fetchInitialData();
          }
          if (this.planType === 'PREMIUM') {
            alert(this.$t('VIEW_LOGIN_ADMIN_FAIL_MESSAGE'));
            throw 'login fail';
          }
        } else {
          throw 'login fail';
        }
      })
      .then(this.$api.getAdminsPasswordDate)
      .then(res => {
        const { pastDays, mustBeChanged } = res.data;

        if (pastDays > 90) {
          this.mustBeChanged = mustBeChanged;
          this.openPopup('AuthPasswordChangeAfterLoginPopup');
        } else {
          this.moveRoot();
        }
      });
  }

  private moveRoot(): void {
    if (this.planType === 'STANDARD') {
      moveStandardAdmin();
    } else {
      const continuePath = this.$route.query?.redirectUrl?.toString() ?? '/';
      this.$router.push(continuePath);
    }
  }

  private openPopup(popupName: string): void {
    this.openPopupName = popupName;
  }

  private closePopup(): void {
    this.openPopupName = '';
  }

  private openSecondAuthPopup(): Promise<LOGIN_STATUS> {
    return new Promise(resolve => {
      this.secondAuthResolve = () => {
        resolve(LOGIN_STATUS.SUCCESS);
      };

      this.openPopup('AuthSecondPopup');
    });
  }

  private completeSecondAuth(authToken: AuthToken): void {
    this.setToken(authToken);
    this.secondAuthResolve();
    this.secondAuthResolve = null;

    alert(this.$t('ALERT_VALID_OTP_VALUE'));
    this.closePopup();
  }

  private setToken(authToken: AuthToken) {
    const { accessToken, refreshToken, lastLogin, planType, autoLogoutMin } = authToken;
    const admin = setToken(accessToken, refreshToken, autoLogoutMin);

    setSessionCookie();
    this.lastLogin = lastLogin;

    if (admin) {
      this.setAdmin(admin);
    }

    this.rememberId ? setCookie('rememberId', this.id) : deleteCookie('rememberId');
    this.planType = planType;
  }

  private handleConsolidatedAuth() {
    window.location.href = `${VUE_APP_COMMERCE_WEB_API}/auth/oauth2/authorize${stringify({
      grant_type: 'authorization_code',
      response_type: 'code',
      client_id: 'oidc-client-shopby',
      scope: 'openid profile email phone',
      redirect_uri: `${VUE_APP_REDIRECT_URL}/oauth/redirect`,
      state: encodeURIComponent(this.$route.query?.redirectUrl?.toString() ?? ''),
    }, {
      addQueryPrefix: true,
    })}`;
  }
}
