



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import EmailInput from '@/components/common/input/EmailInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { GetAuthorityGroupsRequest } from 'ncp-api-supporter';
import { AdminRole } from 'ncp-api-supporter/dist/types/enum';
import { partnerEmailValidType } from '@/const/contents/partner';
import { namespace } from 'vuex-class';
import { Admin } from '@/types';
import { openCreditChangePopup } from '@/helpers/popup';

const adminStore = namespace('admin');
const MAX_ADMIN_IN_BASIC = 3;

@Component({ components: { TextInput, EmailInput, SelectBox } })
export default class AddAdmin extends Vue {
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;
  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  @Prop({ required: true, default: 0 }) private data: { adminCount: number };
  @Prop() private onPositiveClick!: Function;
  @Prop() private onNegativeClick!: Function;

  private readonly emailValidType = partnerEmailValidType;

  private query = {
    adminName: '',
    adminEmail: '',
    adminRole: 'NORMAL' as AdminRole,
    authorityGroupNo: 0,
    externalAccessEnabled: false,
    reasonDetail: '',
  };

  private selectData = [{ name: this.$t('CONFIGURATION.ADMIN.ADD_POPUP_SELECT_AUTHORITY_GROUP_MESSAGE'), value: 0 }];

  created() {
    this.getAuthorityGroups();
  }

  private postAdminsService(): void {
    if (this.admin.plan === 'BASIC' && this.data.adminCount === MAX_ADMIN_IN_BASIC) {
      const result = confirm(this.$t('CONFIGURATION.ADMIN.ALERT_BASIC_NOT_REGISTERED') as string);
      result && openCreditChangePopup(this.commerceAccount, this.admin.mallNos[0].toString());

      return;
    }

    const request = {
      data: this.query,
    };
    this.$api.postAdminsService(request).then(response => {
      if (response && response.status === 201) {
        alert(this.$t('ALERT_SAVED'));
        this.onPositiveClick();
      }
    });
  }

  /**
   * get auth group (all)
   */
  private getAuthorityGroups(): void {
    const request: GetAuthorityGroupsRequest = {
      params: {
        pageSize: 1000,
      },
    };

    this.$api.getAuthorityGroups(request).then(response => {
      if (response && response.status === 200) {
        const authGroupList = response.data.contents;

        // select box 처리
        authGroupList.map(group => {
          this.selectData.push({ name: group.name, value: group.no });
        });
      }
    });
  }

  private save(): void {
    if (!this.query.adminName) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_NAME'));
      (this.$refs.adminName as TextInput).focus();
      return;
    }

    if (!this.query.adminEmail || this.query.adminEmail.split('@').filter(e => !!e).length !== 2) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_INSERT_EMAIL'));
      (this.$refs.adminEmail as EmailInput).focus();
      return;
    }

    if (!this.query.authorityGroupNo) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_SELECT_AUTHORITY_GROUP'));
      (this.$refs.authorityGroupNo as SelectBox).focus();
      return;
    }

    if (!this.query.reasonDetail) {
      alert('사유를 입력해주세요');
      (this.$refs.reasonDetail as TextInput).focus();
      
      return;
    }

    this.postAdminsService();
  }
}
