

























































import { Component, Mixins } from 'vue-property-decorator';
import TermsMixin from '@/views/contents/configuration/basic/terms/TermsMixin.vue';
import { getTermsToolTipOption, TERMS_TYPE } from '@/const/contents/configuration/terms';
import DateCalendar from '@/components/common/DateCalendar.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { DateCalendarOption } from '@/types';
import { changeDateFormat, getToday, isAfter } from '@/utils/dateFormat';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import Editor from '@/components/common/summernote/Editor.vue';

@Component({ components: { Editor, DateCalendar, ToolTip, CheckboxGroup } })
export default class PrivacyPolicy extends Mixins(TermsMixin) {
  private dateCalendarOption: DateCalendarOption = { fromRanges: getToday(), selectedYmd: getToday() };

  private toolTip = getTermsToolTipOption();

  private effectiveDayCalendarKey = 0;

  created() {
    this.noticeList = [
      this.$t('CONFIGURATION.TERMS.NOTICE_PRIVACY_POLICY_0'),
      this.$t('CONFIGURATION.TERMS.NOTICE_PRIVACY_POLICY_1'),
    ];

    this.termsTypeList = [TERMS_TYPE.PI_PROCESS];

    this.throwBottomNavigation();

    this.$on('changeTermsData', () => {
      const enforcementDateTime = this.getTermsData(TERMS_TYPE.PI_PROCESS).enforcementDateTime || getToday();
      this.dateCalendarOption.selectedYmd = changeDateFormat(enforcementDateTime, 'YYYY-MM-DD');

      // 데이터캘린더는 selectedYmd 값 변경시, 해당 값이 설정된 범위가 아니면 안바꿔줌...
      // selectedYmd 를 범위 이외의 값으로 설정하려면 처음에 DatePicker 생성할때 옵션값으로 넣어주는 수밖에 없다. (처음밖에 안됨) --> 즉, DateCalendar 를 remount 하는 수밖에 없넹
      // 따라서 DateCalendar 를 remount 하기 위해 key 값을 변경함.
      this.effectiveDayCalendarKey++;
    });

    this.setShowInHistoryConfiguration();
  }

  protected validate(): boolean {
    const editor = this.getEditor(TERMS_TYPE.PI_PROCESS);
    const terms = this.getTermsData(TERMS_TYPE.PI_PROCESS);
    const title = this.$t('CONFIGURATION.TERMS.PRIVACY_POLICY');

    if (!terms.contents) {
      alert(this.$t('CONFIGURATION.TERMS.ALERT_EMPTY_CONTENTS', { title }));
      editor.focus();
      return false;
    }

    if (!terms.enforcementDateTime) {
      alert(this.$t('CONFIGURATION.TERMS.ALERT_SELECT_EFFECTIVE_DAY'));
      (this.$refs.effectiveDayCalendar as DateCalendar).focus();
      return false;
    }

    if (isAfter(terms.enforcementDateTime)) {
      alert(this.$t('CONFIGURATION.TERMS.NOTICE_CHANGE_DAY'));
      return false;
    }

    if (!confirm(this.$t('CONFIGURATION.TERMS.ALERT_SAVE_EFFECTIVE_DAY', { title }) as string)) {
      return false;
    }

    return true;
  }
}
