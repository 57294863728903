


















































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';

type ReasonDetailLayerPopupType = 'EDIT' | 'DELETE'

@Component({ components: { TextInput } })
export default class ReasonDetailLayerPopup extends Vue {
  @Prop({ required: true }) private isOpen = false
  @Prop({ required: true }) private type: ReasonDetailLayerPopupType = 'EDIT';
  @Prop({ required: true }) private title = '';

  @Emit('confirmReasonDetailModal')
  private confirmReasonDetailModal() {
    const result = this.reasonDetail;
    this.reasonDetail = '';

    return result
  }

  @Emit('closeReasonDetailModal')
  private closeReasonDetailModal() {
    this.reasonDetail = '';

    return;
  }

  private reasonDetail = '';
}
