




import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';
import { clearBottomNavigation } from '@/helpers/bottomNav';
import { getCurrentMallNo } from '@/utils/mall';

@Component({ components: { AdminIframe } })
export default class PersonalInformationIframe extends Vue {
  created() {
    clearBottomNavigation();
  }

  private get remoteSrc() {
    return (
      process.env.VUE_APP_ADMIN_REMOTE_URL +
      this.$route.path +
      `?serviceType=PRO&mallNo=${this.$route.query.mallNo ?? getCurrentMallNo(this)}`
    );
  }
}
