export default Object.freeze({
  CREDIT_CARD: '신용카드',
  PAYCO: 'PAYCO',
  VIRTUAL_ACCOUNT: '가상계좌',
  REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체',
  ESCROW_VIRTUAL_ACCOUNT: '가상계좌-에스크로',
  ESCROW_REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체-에스크로',
  MOBILE: '휴대폰결제',
  POINT: '포인트결제',
  ACCUMULATION: '적립금 전액 사용',
  ZERO_PAY: '0원결제',
  NAVER_PAY: '네이버페이 주문형',
  NAVER_EASY_PAY: '네이버페이 결제형',
  YPAY: '옐로페이',
  KPAY: '케이페이',
  PAYPIN: '페이핀',
  INIPAY: 'INIPay 간편결제',
  ACCOUNT: '무통장입금',
  PHONE_BILL: '전화결제',
  GIFT: '상품권',
  ATM: 'ATM',
  TOASTCAM: '토스트캠',
  ETC: '기타결제수단',
  VERITRANS_CARD: 'Veritrans CreditCard',
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
  KAKAO_PAY: '카카오페이',
  SAMSUNG_PAY: '삼성페이',
  APP_CARD: '앱카드',

  ACCOUNT_TRANSFER: '계좌이체',
});
