





















































































import { Vue, Component } from 'vue-property-decorator';
import SaleSummary from '@/components/root/SaleSummary.vue';
import DownloadManuals from '@/components/root/DownloadManuals.vue';
import QuestionSummary from '@/components/root/QuestionSummary.vue';
import ClaimSummary from '@/components/root/ClaimSummary.vue';
import DelaySummary from '@/components/root/DelaySummary.vue';
import ProductSummary from '@/components/root/ProductSummary.vue';
import ArticleList from '@/components/root/ArticleList.vue';
import { CosBoardsBoardCodeSummary } from 'ncp-api-supporter';
import CosPopup from '@/components/CosPopup.vue';
import { cosSectionCode } from '@/const/contents/cosSection';

const { VUE_APP_COMMERCE_MAIN } = process.env;

@Component({
  components: {
    ProductSummary,
    DelaySummary,
    ClaimSummary,
    QuestionSummary,
    DownloadManuals,
    SaleSummary,
    ArticleList,
    CosPopup,
  },
  computed: {
    cosSectionCode() {
      return cosSectionCode;
    },
  },
})

export default class Home extends Vue {
  /**
   * 게시판 번호들은 알파/리얼 하드코딩으로 처리한다
   * 참고: https://nhnent.dooray.com/project/tasks/3849582055670506077#comment-4016236460470014273
   */
  private readonly CATEGORY_NO = {
    NOTICE_PRIMARY_NO: 34,
    NOTICE_COMMON_NO: 42,
    NOTICE_SHOPBY_NO: 37,
    UPDATE_PRIMARY_NO: 35,
    UPDATE_SHOPBY_NO: 40,
  }

  private readonly link = {
    notice: VUE_APP_COMMERCE_MAIN + `/support/board?primaryCategoryNo=${this.CATEGORY_NO.NOTICE_PRIMARY_NO}&secondaryCategoryNo=${this.CATEGORY_NO.NOTICE_SHOPBY_NO}`,
    upgrade: VUE_APP_COMMERCE_MAIN + `/support/board?primaryCategoryNo=${this.CATEGORY_NO.UPDATE_PRIMARY_NO}&secondaryCategoryNo=${this.CATEGORY_NO.UPDATE_SHOPBY_NO}`,
    faq: VUE_APP_COMMERCE_MAIN + '/customer/faq-list.gd?link=gnb2_3&category=&page=1&category=shopbypro',
  };

  private noticeList: CosBoardsBoardCodeSummary[] = [];
  private upgradeList: CosBoardsBoardCodeSummary[] = [];
  private isMasterNotCommerceAccount = false;

  // private async fetchIsMasterNotCommerceAccount() {
  //   const { data } = await this.$api.getManagerInfo();
  //   return !data?.commerceAccount && data?.role === 'MASTER';
  // }

  created() {
    this.fetchArticles();
  }

  private async fetchArticles() {
    const { NOTICE_COMMON_NO, NOTICE_SHOPBY_NO, UPDATE_SHOPBY_NO } = this.CATEGORY_NO;

    this.noticeList = await this.getArticles([NOTICE_COMMON_NO, NOTICE_SHOPBY_NO].join(','));
    this.upgradeList = await this.getArticles(UPDATE_SHOPBY_NO.toString());
  }

  private getArticles = async (categoryNos: string) => {
    const { data } = await this.$commerceApi.getCosBoardsBoardCodeSummary({
      pathParams: {
        boardCode: 'COMMERCE_NEWS'
      },
      params: {
        size: 7,
        categoryNos
      }
    });

    return data;
  }
}
