




















import { Vue, Component, Ref } from 'vue-property-decorator';
import Lnb from '@/components/navigation/Lnb.vue';
import Sitemap from '@/components/navigation/Sitemap.vue';
import Logo from '@/components/navigation/header/Logo.vue';
import Notificator from '@/components/navigation/header/Notificator.vue';
import SitemapController from '@/components/navigation/header/SitemapController.vue';
import MallListMenu from '@/components/navigation/header/MallListMenu.vue';
import MyAccountMenu from '@/components/navigation/header/MyAccountMenu.vue';
import AppDownload from '@/components/navigation/header/AppDownload.vue';
import { fetchApp } from './lnb';
import { sendTokenPostMessage } from '../iframe/util';

@Component({
  components: { AppDownload, MyAccountMenu, MallListMenu, SitemapController, Notificator, Logo, Lnb, Sitemap },
})
export default class Header extends Vue {
  @Ref()
  private iFrame: HTMLIFrameElement;
  private remoteUrl = `${process.env.VUE_APP_ADMIN_REMOTE_URL}?serviceType=PRO`;

  created() {
    // TODO : menuGroups의 응답 결과는 기획서랑 다르다. (/const/lnb.ts) 기획서 확인 후 menuGroups 동적 메뉴 기능 추가 해야함
    // this.fetchMainMenus();
    // TODO : ncp-client-service 에서는 {mallNo: 0, mallName: '쇼핑몰'}를 배열 0번에 추가하고 있다. (전체 쇼핑몰에 대한 처리?)
  }

  mounted() {
    this.iFrame.onload = () => {
      sendTokenPostMessage(this.iFrame);
    }
  }

  private handleAppSelect = fetchApp;
}
