
import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  GetTermsConfigurationsRequest,
  GetTermsStandardRequest,
  PatchTermsConfigurationsRequest,
  PostTermsRequest,
  Terms,
  TermsType,
} from 'ncp-api-supporter';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { FAIR_LOGO, TERMS_HISTORY_MAIN_TITLE, TERMS_TYPE } from '@/const/contents/configuration/terms';
import { getToday } from '@/utils/dateFormat';
import { RadioBoxGroupOption } from '@/helpers/type';
import { throwPopup } from '@/helpers/popup';
import { getTermsMessageTemplate } from '@/const/words/templateStrings';
import { getCurrentMallNo } from '@/utils/mall';
import Editor from '@/components/common/summernote/Editor.vue';
import { i18nForTerms } from '@/views/contents/configuration/basic/terms/Terms';
import { termsHistoryMainTitleType } from '@/types/terms';

const defaultTerms = {
  no: 0,
  showInHistory: false,
  contents: '',
  fairLogoUrl: '',
  useFairLogo: false,
  used: false,
  mallNo: 0,
  termsType: null,
  fairLogoType: FAIR_LOGO.KR_VERTICAL,
  enforcementDateTime: '',
} as any;

const requiredPersonalInformationTypes = [
  TERMS_TYPE.PI_COLLECTION_AND_USE_REQUIRED,
  TERMS_TYPE.PI_COLLECTION_AND_USE_ON_ORDER,
  TERMS_TYPE.ORDER_INFO_AGREE,
  TERMS_TYPE.PI_COLLECTION_AND_USE_FOR_GUEST_ON_ARTICLE,
  TERMS_TYPE.PI_SELLER_PROVISION,
];

// 변경이력 쇼핑몰 노출설정 가능한 약관타입
const termsTypesForShowingInHistory = ['USE', 'PI_PROCESS'];

@Component({ components: { NoticeBox } })
export default class TermsMixin extends Vue {
  protected i18nForTerms = i18nForTerms;
  protected TERMS_TYPE = TERMS_TYPE;

  protected noticeList = [];
  protected termsTypeList: any[] = [];

  protected list: any[] = [];

  // 이용약관 && 개인정보처리방침 -> 변경이력 쇼핑몰 노출 여부 설정 값
  protected showInHistoryConfiguration: Omit<PatchTermsConfigurationsRequest['data'], 'mallNo'> = {
    showInUseTermsHistory: true, // 이용약관
    showInPrivateProcessHistory: true, // 개인정보 수집/동의 항목
  };

  protected get mallNo(): number {
    return getCurrentMallNo(this);
  }

  // update terms data
  @Watch('termsTypeList')
  private changeTermsTypeList(): void {
    this.getTerms().then(() => {
      this.termsTypeList.map(termsType => {
        this.getEditor(termsType).setHtml(this.getTermsData(termsType).contents);
      });
      this.releaseContents();
    });
  }

  // get terms usable radio option
  protected getRadioOption(termsType): RadioBoxGroupOption<boolean> {
    const radioOption: RadioBoxGroupOption<boolean> = {
      name: `radio-${termsType}`,
      data: [
        { id: `use-${termsType}`, value: true, display: this.$t('CONFIGURATION.TERMS.USED_Y') },
        { id: `not-${termsType}`, value: false, display: this.$t('CONFIGURATION.TERMS.USED_N') },
      ],
    };

    return radioOption;
  }

  created() {
    this.setTermsList();
    this.setTermsHistoryData();
  }

  destroyed() {
    this.$off('changeTermsData');
  }

  // set terms data
  private setTermsList(arrTerms?: Terms[]): void {
    this.list = [];

    if (this.termsTypeList.length === 0) {
      for (const item in TERMS_TYPE) {
        this.list.push({ ...defaultTerms, termsType: item as TermsType });
      }
      return;
    }

    this.termsTypeList.map(termsType => {
      this.list.push({
        ...defaultTerms,
        termsType: termsType,
        mallNo: this.mallNo,
        enforcementDateTime: getToday(),
      });
    });

    arrTerms.map(newTerms => {
      const terms = this.getTermsData(newTerms.termsType);
      Object.assign(terms, {
        ...newTerms,
        mallNo: this.mallNo,
        useFairLogo: newTerms.fairLogoType !== ('NONE' as any),
      });
    });

    this.$emit('changeTermsData');
  }

  // get terms
  protected getTerms(): Promise<void> {
    const request: any = {
      params: {
        mallNo: this.mallNo,
        termsType: this.termsTypeList,
      },
    };
    return this.$api.getTerms(request).then(response => {
      if (response && response.status === 200) {
        this.setTermsList(response.data);
      }
    });
  }

  private patchTermsConfiguration() {
    if (!this.canUpdateShowInHistoryConfiguration()) return;
    const request: PatchTermsConfigurationsRequest = {
      data: {
        mallNo: this.mallNo,
        ...this.showInHistoryConfiguration,
      },
    };
    return new Promise(() => this.$api.patchTermsConfigurations(request));
  }

  // post terms
  protected postTerms() {
    const arrPromise = this.list.map(data => {
      const request: PostTermsRequest = {
        data: {
          mallNo: data.mallNo,
          termsType: data.termsType,
          contents: data.contents,
          // 개인정보/동의항목 중 필수로 사용되는 값은 항상 true 값으로 전달
          used: requiredPersonalInformationTypes.includes(data.termsType) ? true : data.used,
          fairLogoType: !data.useFairLogo ? 'NONE' : data.fairLogoType,
        },
      };

      if (data.enforcementDateTime) {
        request.data.enforcementDate = [
          Number(data.enforcementDateTime.slice(0, 4)),
          Number(data.enforcementDateTime.slice(5, 7)),
          Number(data.enforcementDateTime.slice(8, 10)),
        ];
      }

      return new Promise(() => this.$api.postTerms(request));
    });

    return Promise.allSettled([this.patchTermsConfiguration(), arrPromise].flat()).then(
      // ...termsContentRes 로 처리한 이유는 '이용/탈퇴안내' 처럼 여러 api 요청했을 때 한번에 배열로 만들기 위함
      ([termsHistoryConfigurationRes, ...termsContentRes]) => {
        // 변경이력 쇼핑몰 노출 api 오류 발생 시 처리
        if (termsHistoryConfigurationRes.status === 'rejected') return;

        if (termsContentRes.some(({ status }) => status === 'rejected')) return;

        if (this.list.filter(data => data.termsType === TERMS_TYPE.USE).length > 0) {
          alert(this.$t('CONFIGURATION.TERMS.ALERT_SAVE_TERMS_OF_SERVICE_SUCCESS'));
        } else {
          alert(this.$t('ALERT_SAVED'));
        }
      },
    );
  }

  // get terms standard
  protected getTermsStandard(termsType: TermsType): Promise<void> {
    const request: GetTermsStandardRequest = {
      params: {
        termsType,
      },
    };

    return this.$api.getTermsStandard(request).then(response => {
      if (response && response.status === 200) {
        const terms = this.getTermsData(termsType);
        terms.contents = response.data.contents;
      }
    });
  }

  // 각 terms vue 에서 override 해야함
  protected validate(): boolean {
    return false;
  }

  // post terms 를 위해 각 terms 의 content 데이터를 저장 -> post terms 이후 releaseContents 를 통해 reset
  // 굳이 이렇게 하는 이유는... tui editor 가 상당히 불안정하고 content 가 클수록 성능 이슈가 눈에 보여서... tui editor 에서만 데이터 유지하려고...
  // 테스트 해보고, 성능 문제가 없다 싶으면 content 동기화해서 써도 됨.
  protected setContents(): void {
    this.termsTypeList.map(termsType => {
      this.getTermsData(termsType).contents = this.getEditor(termsType).getHtml();
    });
  }

  // 각 terms 의 content 데이터 삭제
  protected releaseContents(): void {
    this.list.map(item => {
      item.contents = '';
    });
  }

  // click save & validate
  protected save() {
    this.setContents();
    if (!this.validate()) {
      this.releaseContents();
      return;
    }
    this.postTerms().then(() => {
      this.releaseContents();
    });
  }

  protected canUpdateShowInHistoryConfiguration(): boolean {
    return termsTypesForShowingInHistory.includes(this.getMainTitleType() as keyof typeof TERMS_HISTORY_MAIN_TITLE);
  }

  // open replace text popup
  protected openReplaceTextPopup(): void {
    throwPopup({
      name: 'ReplacementTextList',
      data: {
        template: getTermsMessageTemplate(),
        noticeList: [this.$t('CONFIGURATION.TERMS.RT_NOTICE')],
        leftAlign: true,
      },
    });
  }

  // open terms history popup
  private effectiveTermOfServiceNo = 0;
  private effectivePrivacyPolicyNo = 0;
  private setTermsHistoryData() {
    this.$api
      .getTerms({
        params: {
          mallNo: this.mallNo,
          termsType: [TERMS_TYPE.USE, TERMS_TYPE.PI_PROCESS],
        },
      })
      .then(res => {
        if (res && res.status === 200) {
          res.data.filter(term => {
            if (term.termsType === TERMS_TYPE.USE) {
              this.effectiveTermOfServiceNo = term.no;
            } else {
              this.effectivePrivacyPolicyNo = term.no;
            }
          });
        }
      });
  }

  protected async setShowInHistoryConfiguration() {
    const request: GetTermsConfigurationsRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };

    const { data } = await this.$api.getTermsConfigurations(request);
    this.showInHistoryConfiguration = { ...data };
  }

  private getMainTitleType(): termsHistoryMainTitleType {
    if (this.termsTypeList.includes(TERMS_TYPE.PI_COLLECTION_AND_USE_REQUIRED)) {
      return TERMS_HISTORY_MAIN_TITLE.PERSONAL_INFORMATION;
    }
    if (this.termsTypeList.includes(TERMS_TYPE.WITHDRAWAL_GUIDE)) {
      return TERMS_HISTORY_MAIN_TITLE.WITHDRAWAL;
    }
    return this.termsTypeList[0];
  }
  protected openTermsHistoryPopup() {
    throwPopup({
      name: 'TermsHistory',
      data: {
        mallNo: this.mallNo,
        termsType: this.getMainTitleType(),
        termsNo: {
          effectiveTermOfServiceNo: this.effectiveTermOfServiceNo,
          effectivePrivacyPolicyNo: this.effectivePrivacyPolicyNo,
        },
      },
    });
  }

  // bootom area active
  protected throwBottomNavigation(bHideHistoryButton?: boolean) {
    const buttons = [];
    buttons.push({
      type: 'left',
      key: 'replaceText',
      text: this.$t('CONFIGURATION.TERMS.REPLACED_TEXT_BUTTON'),
    });
    if (!bHideHistoryButton) {
      buttons.push({
        type: 'left',
        key: 'termsHistory',
        text: this.$t('CONFIGURATION.TERMS.TERMS_HISTORY_BUTTON'),
      });
    }
    buttons.push({
      type: 'right',
      key: 'save',
      color: 'red',
      text: this.$t('SAVE'),
    });

    throwBottomNavigation({
      buttons,
      onClick: (key: string) => {
        switch (key) {
          case 'replaceText':
            this.openReplaceTextPopup();
            break;
          case 'termsHistory':
            this.openTermsHistoryPopup();
            break;
          case 'save':
            this.save();
            break;
        }
      },
    });
  }

  // get terms data by termsType
  protected getTermsData(termsType: TermsType): any {
    return this.list.filter(item => item.termsType === termsType)[0];
  }

  // reset standard terms by termsType
  protected resetStandard(termsType: TermsType): void {
    this.getTermsStandard(termsType).then(() => {
      this.getEditor(termsType).setHtml(this.getTermsData(termsType).contents);
      this.releaseContents();
    });
  }

  // change select data time by termsType
  protected changeSelectedDateTime(termsType: TermsType, requestDateTime: string): void {
    const terms = this.getTermsData(termsType);
    terms.enforcementDateTime = `${requestDateTime.slice(0, 10)} 00:00:00`;
  }

  // get tui editor by termsType
  protected getEditor(termsType: TermsType): Editor {
    if (this.$refs[`editor-${termsType}`]) {
      if (this.$refs[`editor-${termsType}`] instanceof Array) {
        return this.$refs[`editor-${termsType}`][0];
      }
    }

    return this.$refs[`editor-${termsType}`] as Editor;
  }
}
