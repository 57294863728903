import { payTypes } from '@/const/order.ts';

// TODO: 프리미엄에서 사용가능한 결제수단이랑 프로에서 사용가능한 결제 수단이 다름, 참고: 주문관리 정책서 p.7
export default [
  { value: payTypes.CREDIT_CARD, name: 'ORDER.PAY_TYPE.CREDIT_CARD' },
  { value: payTypes.VIRTUAL_ACCOUNT, name: 'ORDER.PAY_TYPE.VIRTUAL_ACCOUNT' },
  { value: payTypes.REALTIME_ACCOUNT_TRANSFER, name: 'ORDER.PAY_TYPE.REALTIME_ACCOUNT_TRANSFER' },
  { value: payTypes.MOBILE, name: 'ORDER.PAY_TYPE.MOBILE' },
  { value: payTypes.ACCOUNT, name: 'ORDER.PAY_TYPE.ACCOUNT' },
  { value: payTypes.ZERO_PAY, name: 'ORDER.PAY_TYPE.ZERO_PAY' },
  { value: payTypes.ESCROW_VIRTUAL_ACCOUNT, name: 'ORDER.PAY_TYPE.ESCROW_VIRTUAL_ACCOUNT' },
  { value: payTypes.ESCROW_REALTIME_ACCOUNT_TRANSFER, name: 'ORDER.PAY_TYPE.ESCROW_REALTIME_ACCOUNT_TRANSFER' },
  { value: payTypes.ACCUMULATION, name: 'ORDER.PAY_TYPE.ACCUMULATION' },
  { value: payTypes.PAYCO, name: 'ORDER.PAY_TYPE.PAYCO' },
  { value: payTypes.NAVER_EASY_PAY, name: 'ORDER.PAY_TYPE.NAVER_EASY_PAY' },
  { value: payTypes.NAVER_PAY, name: 'ORDER.PAY_TYPE.NAVER_PAY' },

  // 네이버 페이 주문형 홀딩
  // { value: payTypes.NAVER_PAY, name: 'ORDER.PAY_TYPE.NAVER_PAY' },

  // 프로 오픈시점 미지원 결제수단 임시 주석처리
  // { value: payTypes.GIFT, name: 'ORDER.PAY_TYPE.GIFT' },
  // { value: payTypes.ATM, name: 'ORDER.PAY_TYPE.ATM' },
  // { value: payTypes.PHONE_BILL, name: 'ORDER.PAY_TYPE.PHONE_BILL' },
  // { value: payTypes.POINT, name: 'ORDER.PAY_TYPE.POINT' },
  // { value: payTypes.YPAY, name: 'ORDER.PAY_TYPE.YPAY' },
  // { value: payTypes.KPAY, name: 'ORDER.PAY_TYPE.KPAY' },
  // { value: payTypes.PAYPIN, name: 'ORDER.PAY_TYPE.PAYPIN' },
  // { value: payTypes.INIPAY, name: 'ORDER.PAY_TYPE.INIPAY' },
  // { value: payTypes.PAYPAL, name: 'ORDER.PAY_TYPE.PAYPAL' },
  // { value: payTypes.STRIPE, name: 'ORDER.PAY_TYPE.STRIPE' },
  { value: payTypes.KAKAO_PAY, name: 'ORDER.PAY_TYPE.KAKAO_PAY' },
  // { value: payTypes.NAVER_EASY_PAY, name: 'ORDER.PAY_TYPE.NAVER_EASY_PAY' },
  // { value: payTypes.SAMSUNG_PAY, name: 'ORDER.PAY_TYPE.SAMSUNG_PAY' },
  // { value: payTypes.ETC, name: 'ORDER.PAY_TYPE.ETC' },
  { value: payTypes.APP_CARD, name: 'ORDER.PAY_TYPE.APP_CARD' },
];
