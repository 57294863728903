import { i18n } from '@/main';
import { mall } from '@/utils/mall';
import { addMonth, getToday } from '@/utils/dateFormat';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { formatCurrency } from '@/utils/numberFormat';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';
import { StatisticSynthesisDate } from '@/types';

const date = new Date();
export const today = getToday();
export const aMonthAgo = addMonth(today, -1);
// --- params ----
export const getDefaultParams = () => ({
  mallNo: mall.lastCreatedMall.mallNo,
  startYmd: aMonthAgo,
  endYmd: today,
  includesClaim: false,
  platformTypes: 'PC,MOBILE_WEB,MOBILE_APP',
  payTypes:
    'CREDIT_CARD,ACCOUNT,MOBILE,REALTIME_ACCOUNT_TRANSFER,VIRTUAL_ACCOUNT,GIFT,ATM,PAYCO,ZERO_PAY,ACCUMULATION,PHONE_BILL,POINT,YPAY,KPAY,PAYPIN,INIPAY,PAYPAL,STRIPE,KAKAO_PAY,NAVER_EASY_PAY,NAVER_PAY,ESCROW_REALTIME_ACCOUNT_TRANSFER,ESCROW_VIRTUAL_ACCOUNT,APP_CARD',
  memberGradeNo: 0,
  genderTypes: '',
  memberType: 'ALL',
  memberJoinType: '',
  page: 1,
  size: 30,
});

export const defaultDateInfo = (): StatisticSynthesisDate => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
});

export const getYearMonth = (date: string) => {
  const [year, month] = date.split('-');

  return {
    year: Number(year),
    month: Number(month),
  };
};

// --- 그리드 옵션
export const getSalesByDateGridProps = () => ({
  header: {
    columns: [
      {
        name: 'totalSaleAmt',
        renderer: props => {
          props.tooltipId = 'totalSaleAmt';
          return headerWithTooptipRenderer(props);
        },
      },
      {
        name: 'excelDownload',
        renderer: props => {
          props.tooltipId = 'excelDownload';
          return headerWithTooptipRenderer(props);
        },
      },
      {
        name: 'dailyExcelDownload',
        renderer: props => {
          props.tooltipId = 'excelDownload';
          return headerWithTooptipRenderer(props);
        },
      },
    ],
  },
  columns: [
    {
      header: 'STATISTIC.SALES.DAY',
      name: 'ymd',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'STATISTIC.SALES.ORDER_COUNT',
      name: 'totalOrderCnt',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'STATISTIC.SALES.SALES_COUNT',
      name: 'totalSaleCnt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.SALES_AMOUNT_A',
      name: 'totalSaleAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.PROMOTION_DISCOUNT_AMOUNT',
      name: 'totalPromotionDiscountAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.ACCUMULATION_AMOUNT',
      name: 'totalAccumulationAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.DELIVERY_AMOUNT',
      name: 'totalDeliveryAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.EXTERNAL_PAY_AMOUNT',
      name: 'totalExternalPayAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.TOTAL_PAY_AMOUNT',
      name: 'totalPayAmt',
      align: 'center',
      minWidth: 180,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'DOWNLOAD',
      name: 'dailyExcelDownload',
      align: 'center',
      minWidth: 60,
      formatter: () => getAnchorHTML(i18n.t('DOWNLOAD')),
    },
  ],
  options: {
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },

  displayOptions: {
    showsRightArea: true,
    hasExcelDownloadButton: false,
    hasSettingButton: false,
    pageSizeKey: 'size',
    headerTooltipOption: [
      {
        id: '#totalSaleAmt',
        popupData: { data: { message: i18n.t('STATISTIC.SALES.SALES_AMT_TOOLTIP'), leftAlign: true } },
      },
      {
        id: '#excelDownload',
        popupData: {
          data: { message: i18n.t('STATISTIC.SALES.SALES_BY_DATE_EXCEL_DOWNLOAD_TOOLTIP'), leftAlign: true },
        },
      },
      {
        id: '#dailyExcelDownload',
        popupData: {
          data: { message: i18n.t('STATISTIC.SALES.SALES_BY_DATE_EXCEL_DOWNLOAD_TOOLTIP'), leftAlign: true },
        },
      },
    ],
  },
});
