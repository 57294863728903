















































import { Component, Mixins } from 'vue-property-decorator';
import TermsMixin from '@/views/contents/configuration/basic/terms/TermsMixin.vue';
import { getTermsRadioBoxOption, TERMS_TYPE, withdrawalTypeList } from '@/const/contents/configuration/terms';
import RadioGroup from '@/components/common/RadioGroup.vue';
import VueI18n from 'vue-i18n';
import LocaleMessages = VueI18n.LocaleMessages;
import Editor from '@/components/common/summernote/Editor.vue';
import { TermsType } from 'ncp-api-supporter';

@Component({ components: { Editor, RadioGroup } })
export default class Withdrawal extends Mixins(TermsMixin) {
  private getTermsTitle(termsType: TermsType): string | LocaleMessages {
    switch (termsType) {
      case TERMS_TYPE.WITHDRAWAL_GUIDE:
        return this.$t('CONFIGURATION.TERMS.WITHDRAWAL_GUIDE');
      case TERMS_TYPE.ACCESS_GUIDE:
        return this.$t('CONFIGURATION.TERMS.ACCESS_GUIDE');
    }
    return '';
  }

  private radioOption = getTermsRadioBoxOption();

  created() {
    this.noticeList = [this.$t('CONFIGURATION.TERMS.NOTICE_WITHDRAWAL')];
    this.termsTypeList = withdrawalTypeList;

    this.throwBottomNavigation();
  }

  mounted() {
    const editor = this.getEditor(this.termsTypeList[0]);
    editor.focus();
  }

  protected validate(): boolean {
    for (const termsType of this.termsTypeList) {
      const terms = this.getTermsData(termsType);
      if (terms.used && !terms.contents) {
        const editor = this.getEditor(termsType);
        const title = this.getTermsTitle(termsType);
        alert(this.$t('CONFIGURATION.TERMS.ALERT_EMPTY_CONTENTS', { title }));
        editor.focus();
        return false;
      }
    }

    return true;
  }
}
