

























































import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator';
import { GetCosSectionsSectionCodeResponse } from 'ncp-api-supporter';
import { PromotionSlider } from '@/components/addition/PromotionSlider';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { setCookie } from '@/utils/cookie';
import { addDay } from '@/utils/dateFormat';
import moment from 'moment';

@Component({})
export default class CosPopup extends Vue {
  @Prop() readonly sectionCode: string;
  @Ref()
  private readonly cosPopupWrapElementRef!: HTMLDivElement;
  @Ref()
  private readonly cosPopupElementRef!: HTMLDivElement;
  @Ref()
  private readonly cosPopupUlElementRef!: HTMLUListElement;
  @Ref()
  private readonly prevArrowRef!: HTMLAnchorElement;
  @Ref()
  private readonly nextArrowRef!: HTMLAnchorElement;

  private banners = [];
  private slider: PromotionSlider | null = null;
  private popupSlider: PromotionSlider | null = null;
  private cosPopup: GetCosSectionsSectionCodeResponse | null = null;
  private cosPopupIndex = 1;

  @Watch('isOpenCosPopup')
  private async changedCosPopup() {
    await this.$nextTick();

    const additional = { ...Object.fromEntries(new URLSearchParams(this.cosPopup.additionalInput).entries()) };

    if (additional.isDimmed === 'true') {
      this.cosPopupWrapElementRef.classList.add('dimmed');

      this.cosPopupElementRef.style.left = `calc(${this.cosPopup.positionLeft} - ${this.cosPopup.width / 2}px)`;
      this.cosPopupElementRef.style.top = `calc(${this.cosPopup.positionTop} - ${(this.cosPopup.height + 52) / 2}px)`;
      this.cosPopupElementRef.style.width = `${this.cosPopup.width}px`;
      this.cosPopupElementRef.style.height = `${this.cosPopup.height + 52}px`;
    } else {
      this.cosPopupWrapElementRef.style.left = `calc(${this.cosPopup.positionLeft} - ${this.cosPopup.width / 2}px)`;
      this.cosPopupWrapElementRef.style.top = `calc(${this.cosPopup.positionTop} - ${
        (this.cosPopup.height + 52) / 2
      }px)`;
      this.cosPopupWrapElementRef.style.width = `${this.cosPopup.width}px`;
      this.cosPopupWrapElementRef.style.height = `${this.cosPopup.height + 52}px`;
    }

    this.initCosPopup({
      autoplay: additional.autoplay === 'true',
      delay: additional.delay,
      infinite: additional.infinite === 'true',
    });
  }

  private afterPopupSlideChange() {
    $('#cosPopup').on('afterChange', (_, slick) => {
      // 마지막에 도달했을 때 무한 롤링이 아니고 자동 롤링인 경우 슬라이드를 1로 이동 후 재생
      if (slick.slideCount === slick.currentSlide + 1) {
        if (slick.options.autoplay && !slick.options.infinite) {
          slick.paused = true;
          slick.autoPlayClear();
          setTimeout(() => {
            slick.currentSlide = 1;
            slick.paused = false;
          }, slick.options.autoplaySpeed);
        }
      }
      this.cosPopupIndex = slick.currentSlide + 1;
    });
  }

  private initCosPopup({ autoplay, delay, infinite }: { autoplay?: boolean; delay?: string; infinite?: boolean }) {
    const { cosPopupUlElementRef, prevArrowRef, nextArrowRef } = this;

    this.popupSlider = new PromotionSlider({
      slideElement: cosPopupUlElementRef,
      prevArrow: prevArrowRef,
      nextArrow: nextArrowRef,
      dots: false,
      autoplay,
      autoplaySpeed: delay ? Number(delay) : 5000,
      infinite,
    });

    this.afterPopupSlideChange();
  }

  private mainModal = {
    [this.sectionCode]: { isClose: true },
  };

  private get isOpenCosPopup() {
    return !this.mainModal[this.sectionCode].isClose && this.cosPopup?.posts.length > 0;
  }

  private setBanner() {
    this.cosPopup.posts.forEach(post => {
      this.banners.push(post.bodyText);
    });
  }

  private resetModalState() {
    Cookies.remove(this.sectionCode);
  }

  private setModalState() {
    this.mainModal[this.sectionCode].isClose = Cookies.get(this.sectionCode) === 'close';
  }

  private async fetchCosSections(): Promise<void> {
    const { data } = await this.$cosWebApi.getCosSectionsSectionCode({
      pathParams: { sectionCode: this.sectionCode },
    });

    if (data.sectionType !== 'POP_UP') {
      return;
    }

    this.cosPopup = {
      ...data,
      positionTop: data.positionTop.toString().replace('_', ''),
      positionLeft: data.positionLeft.toString().replace('_', ''),
    };

    this.setBanner();
    if (!data.cookieAliveDay) {
      this.resetModalState();
    }
    this.setModalState();
  }

  private closeModal(data) {
    const expireDate = moment(addDay(new Date(), data.expireDate ?? 1)).toDate();
    if (data.isSetCookie) {
      setCookie(data.key, data.value, expireDate);
    }
    const { name } = data;
    this.mainModal[name].isClose = true;
  }

  mounted() {
    this.fetchCosSections();
  }
}
